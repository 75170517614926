import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FilterPopup from './FilterPopup';
import ManageColumn from './ManageColumn';
import { userSearchAPI } from 'src/api/UserApi';
import DatePickerComponent from 'src/components/DatePickerComponent';


const EmployeeDataContainer = () => {

  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedDesignation, setSelectedDesignation] = useState('');


  const getUserEmployeeData = async () => {
    let body = {
      pageLength: 100,
      where: {
        date: {
          $gte: startDate || null,
          $lte: endDate || null
        }
      }
    }
    try {
      const resp = await userSearchAPI(body);
      console.log('response from the user Employee', resp?.data?.data);

      // Debugging: Log the selected filters and the fetched data
      console.log('Selected Department:', selectedDepartment);
      console.log('Selected Designation:', selectedDesignation);
      console.log('Fetched Data:', resp.data.data);

      const filteredData = resp.data.data.filter(user => {
        const userDate = new Date(user.ts);
        const isWithinDateRange = (!startDate || userDate >= new Date(startDate)) && (!endDate || userDate <= new Date(endDate));
        const matchesSearch = `${user.firstName} ${user.lastName}`.toLowerCase().includes(search.toLowerCase());
        const matchesDepartment = !selectedDepartment || user.departmentId?.departmentName === selectedDepartment;
        const matchesDesignation = !selectedDesignation || user.designationId?.designationName === selectedDesignation;

        // Debugging: Log each user's data and the filter conditions
        console.log('User Data:', user);
        console.log('Matches Department:', matchesDepartment);
        console.log('Matches Designation:', matchesDesignation);

        return isWithinDateRange && matchesSearch && matchesDepartment && matchesDesignation;
      });
      setData(filteredData);
    } catch (error) {
      console.log('error from employee data', error);
    }
  }


  useEffect(() => {
    getUserEmployeeData();
  }, [search, startDate, endDate, selectedDepartment, selectedDesignation]);

  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setStartDate(selectedDates[0]);
      setEndDate(selectedDates[1]);
    } else {
      setStartDate('');
      setEndDate('');
    }
  }

  const handleApplyFilters = (filters) => {
    console.log('Applying Filters:', filters);
    setSelectedDepartment(filters.department);
    setSelectedDesignation(filters.designation);
  };


  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24"> 
                Employee Data
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="javascript:void(0)"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Employee Data </li>
              </ul>
              <div className="d-flex gap-2 align-items-center py-16">
                {/* Search start */}
                <div className="search">
                  <span className="searchBtn position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
                      ></path>
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M338.29 338.29L448 448"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control radius-8"
                    id="search"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}
                  />
                </div>
                {/* Search End */}
                {/* Date Range piker start  */}
                {/* <div className="datepker position-relative">
                  <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <iconify-icon
                      icon="solar:calendar-linear"
                      className="icon text-lg"
                    />
                  </span>
                  <input
                    className="form-control radius-8 bg-base"
                    type="date"
                    id="flat"
                    placeholder="Date"
                  />
                </div> */}
                <DatePickerComponent onDateRangeChange={handleDateRangeChange} />
                {/* Date Range piker end  */}
                {/* Manage Column btn start */}
                {/* <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#manageColRightPopup"
                    aria-controls="manageColRightPopup"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 text-sm radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      fill="currentColor"
                      className="bi bi-columns"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm8.5 0v8H15V2zm0 9v3H15v-3zm-1-9H1v3h6.5zM1 14h6.5V6H1z" />
                    </svg>{" "}
                    Manage Column
                  </button>
                </div> */}
                {/* Manage Column btn end */}
                {/* Filter btn start */}
                <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#filterRightPopup"
                    aria-controls="filterRightPopup"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
                      />
                    </svg>{" "}
                    Filter
                  </button>
                </div>
                {/* Filter btn end */}
              </div>
              {/* <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-stack"
                    viewBox="0 0 16 16"
                  >
                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
                  </svg>{" "}
                  Project Code_315B
                </h6>
              </div> */}
              <div
                className="table-responsive table-container"
                id="table-container"
              >
                <table className="table basic-border-table mb-0 table-hover">
                  <thead>
                    <tr className="dnd-moved">
                      <th className="w30px dragablefalse" draggable="false">
                        <input className="form-check-input" type="checkbox" />{" "}
                      </th>
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th>User Id </th>
                      <th>Name</th>
                      <th>Department </th>
                      <th>Designation </th>
                      <th className="text-center">View </th>
                    </tr>
                  </thead>


                  <tbody id="table-body">
                    {data.map((user, index) => (
                      <tr key={user._id} className="dnd-moved">
                        <td draggable="false">
                          <input className="form-check-input" type="checkbox" />
                        </td>
                        <td draggable="false">
                          <label className="form-check-label">{index + 1}</label>
                        </td>
                        <td>
                          <span className="bg-primary-focus btn-primary-900 text-bas px-24 py-2 rounded-pill fw-medium text-sm">
                            {user.employeeID}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <span className="userimg">
                              <img src={user.profilePicture} alt="Profile" />
                            </span>
                            <div className="flex-grow-1">
                              <h6 className="text-md mb-0 fw-medium text-primary-600">
                                {user.firstName} {user.lastName}
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>{user.departmentId?.departmentName}</td>
                        <td>{user.designationId?.designationName}</td>
                        <td className="text-center">
                            <Link to={`/view-production/${user._id}`} className="text-primary-600"> 
                            View Production
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FilterPopup onApplyFilters={handleApplyFilters} />
      <ManageColumn />
    </>
  )
}

export default EmployeeDataContainer