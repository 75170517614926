import { useState } from "react"
import TaskTree from "./TaskTree"
import { useDispatch } from "react-redux";
import {productionAssignModuleIdAction } from "../actions/ProductionActions";

const ModuleTree=({module})=>{
    // const [isOpen,setIsOpen] = useState(false)
    const dispatch = useDispatch();
    const handleModuleSelect = (e,moduleDetails)=>{
        e.stopPropagation(); // Prevent event propagation
        // setIsOpen(!isOpen)
        dispatch(productionAssignModuleIdAction({data:moduleDetails._id}))
        document.querySelectorAll('.has-children').forEach((element) => {
          element.classList.remove('is-open');
      });
    }
    return (
      <> 
      <li   onClick={(e) => handleModuleSelect(e,module)}>
            <div className="acnav__label acnav__label--level8">
                {module.moduleName}
            </div> 
            </li>



      </>
    )
  }
  export default ModuleTree