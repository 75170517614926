import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { taskCreateAPI } from 'src/api/TaskApi';

const UpsertTask = ({ onTaskSaved }) => {
  const { projectId } = useParams();
  const [userId, setUserId] = useState(null);
  const [taskname, setTaskName] = useState('');
  const [target, setTarget] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUserId(storedUser?._id);
    }
  }, []);

  const saveTask = async () => {
    let body = {
      taskName: taskname,
      target: target,
      unit: selectedUnit,
      projectId: projectId,
      by: userId,
      item: selectedItem
    };

    console.log('body of save task', body);

    try {
      const resp = await taskCreateAPI(body);
      console.log('response from the create task', resp?.data);
      if (onTaskSaved) {
        onTaskSaved(); // Trigger the searchTask function from TaskContainer
      }
      setTaskName('');
      setTarget('');
      setSelectedUnit('');
      setSelectedItem('');
    } catch (error) {
      console.log('error from the create task', error);
    }
  };

  const handleItemChange = (item) => {
    setSelectedItem(item);
    if (item === 'Daily Target') {
      setSelectedUnit('Per Day');
    } else {
      setSelectedUnit('Min');
    }
    setDropdownOpen(false); // Close the dropdown after selection
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Inline styles
  const styles = {
    customDropdown: {
      position: 'relative',
      border: '1px solid #ced4da',
      borderRadius: '4px',
      padding: '4px 12px',
      cursor: 'pointer',
    },
    selectedItem: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px',
      padding: '4px 8px',
      marginRight: '4px',
    },
    placeholder: {
      color: '#6c757d',
    },
    dropdownOptions: {
      position: 'absolute',
      top: '100%',
      left: '0',
      right: '0',
      zIndex: '1000',
      backgroundColor: '#fff',
      border: '1px solid #ced4da',
      maxHeight: '200px',
      overflowY: 'auto',
      borderRadius: '4px',
    },
    dropdownOption: {
      padding: '8px 12px',
      cursor: 'pointer',
    },
    dropdownOptionHover: {
      // backgroundColor: '#f8f9fa',
    }
  };

  return (
    <div
      className="offcanvas offcanvas-end filterSecPopup"
      id="AddNewTaskPopup"
      aria-labelledby="addNewTaskSec"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="addNewTaskSec">
          Add New Task
        </h5>
        <button
          type="button"
          className="btn-close d-none"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div className="offcanvas-body pt-0">
        <div className="assignPopoHeight">
          <div className="selectSec pt-0">
            <label htmlFor="">Task</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Task Name"
              value={taskname}
              onChange={(e) => setTaskName(e.target.value)}
            />
          </div>
          <div className="selectSec pt-0">
            <label htmlFor="">Target</label>
            <div className="costSec">
              <div className="costinputSec">
                <input
                  type="number"
                  min={0}
                  defaultValue={0}
                  className="w-100"
                  value={target}
                  onChange={(e) => setTarget(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="selectSec filterStyleSec pt-2">
            <label htmlFor="">Item</label>
            <div style={styles.customDropdown}>
              <div
                style={styles.selectedItem}
                onClick={toggleDropdown}
              >
                {selectedItem || 'Select Item'}
              </div>
              {dropdownOpen && (
                <div style={styles.dropdownOptions}>
                  <div
                    style={styles.dropdownOption}
                    onClick={() => handleItemChange('Daily Target')}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = styles.dropdownOptionHover.backgroundColor)}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                  >
                    Daily Target
                  </div>
                  <div
                    style={styles.dropdownOption}
                    onClick={() => handleItemChange('Bound')}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = styles.dropdownOptionHover.backgroundColor)}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                  >
                    Bound
                  </div>
                  <div
                    style={styles.dropdownOption}
                    onClick={() => handleItemChange('Open')}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = styles.dropdownOptionHover.backgroundColor)}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                  >
                    Open
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="selectSec filterStyleSec pt-2">
            <label htmlFor="">Unit</label>
            <input
              type="text"
              className="form-control"
              value={selectedUnit}
              disabled
            />
          </div>
        </div>
        <div className="">
          <button
            type="button"
            className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
            onClick={saveTask}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpsertTask;
