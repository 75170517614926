import React, { useState, useEffect } from 'react';
import { userSearchAPI } from 'src/api/UserApi';

const FilterPopup = ({ onApplyFilters }) => {
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedDesignation, setSelectedDesignation] = useState('');
  console.log('department and designation', selectedDepartment, selectedDesignation);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await userSearchAPI({ pageLength: 100 });
        if (resp?.data?.data) {
          const departmentSet = new Set();
          const designationSet = new Set();

          resp.data.data.forEach(user => {
            if (user.departmentId && user.departmentId.departmentName) {
              departmentSet.add(user.departmentId.departmentName);
            }
            if (user.designationId && user.designationId.designationName) {
              designationSet.add(user.designationId.designationName);
            }
          });

          setDepartments([...departmentSet]);
          setDesignations([...designationSet]);
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);

  const dropdownStyle = {
    width: '100%',
    padding: '0.5rem',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
  };

  const handleSaveFilter = () => {
    console.log('butoon clicked filter ');
    
    onApplyFilters({ department: selectedDepartment, designation: selectedDesignation });
  };

  return (
    <div className="offcanvas offcanvas-end filterSecPopup" id="filterRightPopup" aria-labelledby="filterRightLayout">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="filterRightLayout">Filter</h5>
        <button type="button" className="btn-close d-none" data-bs-dismiss="offcanvas" aria-label="Close" />
      </div>
      <div className="offcanvas-body pt-0">
        <div className="assignPopoHeight">
          <div className="selectSec filterStyleSec">
            <label htmlFor="departmentSec">Department</label>
            <select
              id="departmentSec"
              style={dropdownStyle}
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
            >
              <option value="">Select Department</option>
              {departments.map((dept, index) => (
                <option key={index} value={dept}>{dept}</option>
              ))}
            </select>
          </div>
          <div className="selectSec filterStyleSec">
            <label htmlFor="cadreMenuSec">Designation</label>
            <select
              id="cadreMenuSec"
              style={dropdownStyle}
              value={selectedDesignation}
              onChange={(e) => setSelectedDesignation(e.target.value)}
            >
              <option value="">Select Designation</option>
              {designations.map((desig, index) => (
                <option key={index} value={desig}>{desig}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="">
          <button
            type="button"
            className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
            onClick={handleSaveFilter}
          >
            Save Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterPopup;
