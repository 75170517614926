import { useState } from "react"
import SubProjectTree from "./SubProjectsTree"
import { productionAssignClientIdAction } from "../actions/ProductionActions"
import { useDispatch } from "react-redux"

const ProjectTree=({index,item})=>{
    const [isOpen,setIsOpen] = useState(false)
    const dispatch = useDispatch();
    const handleSelectProject = (e,clientId)=>{
        e.stopPropagation(); // Prevent event propagation
        setIsOpen(!isOpen)
        dispatch(productionAssignClientIdAction({data:clientId}))
    }
    return (
      <>
      <li 
      key={index}
      className={`has-children ${isOpen ? 'is-open' : ''}`} 
      onClick={(e) => {handleSelectProject(e,item._id)}}
      >
        <div className="acnav__label acnav__label--level2">
            {item.ClientProjectCode}
        </div>
        {/* start level 3 */}
        <ul className="acnav__list acnav__list--level3">
            <li>
            <a
                className="proHeadColoPink acnav__link acnav__link--level3"
                href="javascript:void(0)"
            >
                Select Subcode
            </a>
            </li>
            {
                item.projects.map((project,ProIndex)=>{
                    return(
                        <SubProjectTree key={ProIndex} project={project}/>
                    )
                })
            } 
        </ul>
        {/* end level 3 */}
        </li> 
      </>
    )
  }
  export default ProjectTree