import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const ProjectPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    ProjectName: PropTypes.string,
});

export const ProjectDefaultProps = { 
    ProjectName: '',
    _id: undefined
};

export const ProjectYupSchema = Yup.object().shape({ 
    ProjectName: Yup.string().required('Required'),
});

export default {
    ProjectDefaultProps,
    ProjectPropTypes,
    ProjectYupSchema,
};
