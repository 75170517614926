import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const ClientPropTypes = PropTypes.shape({
    ClientRegisteredName: PropTypes.string.isRequired,
    ClientCommercialName: PropTypes.string.isRequired,
    ClientAddress: PropTypes.string.isRequired,
});

export const ClientDefaultProps = {
    ClientRegisteredName: '',
    ClientCommercialName: '',
    ClientGST: '',
    ClientAddress: '',
    ClientWebsite: '',
    ClientPhoneNo: '',
    ClientService: [],
};

export const ClientYupSchema = Yup.object().shape({
    ClientRegisteredName: Yup.string()
        .min(4, 'Client Registered Name Must be at least 4 characters')
        .required('Client Registered Name Required'),
    ClientCommercialName: Yup.string()
        .min(4, 'Client Commercial Name Must be at least 4 characters')
        .required('Client Commercial Name Required'),
    ClientAddress: Yup.string()
        .min(10, 'Client Address Must be at least 10 characters')
        .required('Client Address Required'),
});

export default {
    ClientDefaultProps,
    ClientPropTypes,
    ClientYupSchema,
};
