import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { useSelector,shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux"; 

const LeftNavBar = () => { 
  const {user} = useSelector(state=>state.auth,shallowEqual);
  useEffect(() => {
    // Sidebar submenu collapsible
    $(".sidebar-menu .dropdown").on("click", function() {
      const item = $(this);
      item.siblings(".dropdown").children(".sidebar-submenu").slideUp();
      item.siblings(".dropdown").removeClass("dropdown-open");
      item.siblings(".dropdown").removeClass("open");
      item.children(".sidebar-submenu").slideToggle();
      item.toggleClass("dropdown-open");
    });

    $(".sidebar-toggle").on("click", function() {
      $(this).toggleClass("active");
      $(".sidebar").toggleClass("active");
      $(".dashboard-main").toggleClass("active");
    });

    $(".sidebar-mobile-toggle").on("click", function() {
      $(".sidebar").addClass("sidebar-open");
      $("body").addClass("overlay-active");
    });

    $(".sidebar-close-btn").on("click", function() {
      $(".sidebar").removeClass("sidebar-open");
      $("body").removeClass("overlay-active");
    });

    // Keep the current page active
    const currentPath = window.location.pathname;
    $("ul#sidebar-menu a").filter(function () {
      return this.pathname === currentPath;
    }).addClass("active-page").parent().addClass("active-page")
      .parentsUntil("ul#sidebar-menu", "li").addClass("open");

    // Table Header Checkbox checked all
    $('#selectAll').on('change', function () {
      $('.form-check .form-check-input').prop('checked', $(this).prop('checked')); 
    }); 

    // Remove Table Tr when click on remove btn
    $('.remove-btn').on('click', function () {
      $(this).closest('tr').remove(); 

      // Check if the table has no rows left
      if ($('.table tbody tr').length === 0) {
        $('.table').addClass('bg-danger');

        // Show notification
        $('.no-items-found').show();
      }
    });

    return () => {
      // Cleanup event listeners on unmount
      $(".sidebar-menu .dropdown").off("click");
      $(".sidebar-toggle").off("click");
      $(".sidebar-mobile-toggle").off("click");
      $(".sidebar-close-btn").off("click");
      $('#selectAll').off('change');
      $('.remove-btn').off('click');
    };
  }, []);

  // Utility functions for theme toggling
  useEffect(() => {
    const button = document.querySelector("[data-theme-toggle]");
    const localStorageTheme = localStorage.getItem("theme");

    const calculateSettingAsThemeString = ({ localStorageTheme }) => {
      return localStorageTheme !== null ? localStorageTheme : "light";
    };

    const updateButton = ({ buttonEl, isDark }) => {
      const newCta = isDark ? "dark" : "light";
      buttonEl.setAttribute("aria-label", newCta);
      buttonEl.innerText = newCta;
    };

    const updateThemeOnHtmlEl = ({ theme }) => {
      document.querySelector("html").setAttribute("data-theme", theme);
    };

    let currentThemeSetting = calculateSettingAsThemeString({ localStorageTheme });

    updateButton({ buttonEl: button, isDark: currentThemeSetting === "dark" });
    updateThemeOnHtmlEl({ theme: currentThemeSetting });

    const handleButtonClick = () => {
      const newTheme = currentThemeSetting === "dark" ? "light" : "dark";
      localStorage.setItem("theme", newTheme);
      updateButton({ buttonEl: button, isDark: newTheme === "dark" });
      updateThemeOnHtmlEl({ theme: newTheme });
      currentThemeSetting = newTheme;
    };

    button.addEventListener("click", handleButtonClick);

    return () => {
      button.removeEventListener("click", handleButtonClick);
    };
  }, []);
  // const auth = useSelector((state) => state.auth.user);
  // const { jwtToken: authToken, otherInformation: { profilePicture } = {} } =
  //   auth || {};
  // const navigate = useNavigate();
  // const dispatch = useDispatch();  

  return (
    <>
       <aside className="sidebar">
  <button type="button" className="sidebar-close-btn">
    <iconify-icon icon="radix-icons:cross-2" />
  </button>
  <div>
    <a href="/" className="sidebar-logo text-center">
      <img
        src="/assets/images/left-sidebar/logo.svg"
        alt="site logo"
        className="light-logo"
      />
      <img
        src="/assets/images/left-sidebar/logo.svg"
        alt="site logo"
        className="dark-logo"
      />
      <img
        src="/assets/images/left-sidebar/acadedigital-icon.svg"
        alt="site logo"
        className="logo-icon"
      />
    </a>
  </div>
  <div className="sidebar-menu-area">
    <ul className="sidebar-menu" id="sidebar-menu">
      <li className="userPList">
        <div className="text-center userNDEID">
          <span>
            <img
              src={`${(user.profilePicture)?user.profilePicture:'/assets/images/user.png'} `}
              className="img-fluid"
            />
          </span>
          <div className="">
            <div className="name">{`${user.firstName} ${user.lastName}`}</div>
            <div className="designation">{user.designationDetails.designationName}</div>
            <div className="emplyId">{user.username}</div>
          </div>
        </div>
        <div
          className="accordion accordion-flush emplyDtalsMain"
          id="accordionuserFile"
        >
          <div className="accordion-item border-0 p-0">
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionuserFile"
            >
              <div className="accordion-body">
                <ul className="emplyDtalsList">
                  <li>
                    <span className="d-block">Department</span>
                    {user.departmentDetails.departmentName}
                  </li>
                  {/* <li>
                    <span className="d-block">Grade</span>
                    E-2
                  </li>
                  <li>
                    <span className="d-block">Cadre</span>
                    UI Designer
                  </li>
                  <li>
                    <span className="d-block">Subject</span>
                    English
                  </li> 
                  <li>
                    <span className="d-block">Reporting Manager</span>
                    Harendra Singh Tomar
                  </li>*/}
                </ul>
              </div>
            </div>
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed justify-content-center"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                <span id="emplyDtals">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                  >
                    <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z" />
                  </svg>
                </span>
              </button>
            </h2>
          </div>
        </div>
      </li>
      <li>
          <Link to="/" >
          <span className="menu-icon">
            <svg
              width={17}
              height={17}
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.88469 2.27001C7.88469 1.36749 7.15221 0.63501 6.24969 0.63501H1.89013C0.987607 0.63501 0.255127 1.36749 0.255127 2.27001V6.62957C0.255127 7.53209 0.987607 8.26457 1.89013 8.26457H6.24969C7.15221 8.26457 7.88469 7.53209 7.88469 6.62957V2.27001ZM6.79513 2.27001V6.62957C6.79513 6.93041 6.55053 7.17501 6.24969 7.17501H1.89013C1.58929 7.17501 1.34469 6.93041 1.34469 6.62957V2.27001C1.34469 1.96917 1.58929 1.72457 1.89013 1.72457H6.24969C6.55053 1.72457 6.79513 1.96917 6.79513 2.27001Z"
                fill="#323338"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.88469 10.99C7.88469 10.0875 7.15221 9.35498 6.24969 9.35498H1.89013C0.987607 9.35498 0.255127 10.0875 0.255127 10.99V15.3495C0.255127 16.2521 0.987607 16.9845 1.89013 16.9845H6.24969C7.15221 16.9845 7.88469 16.2521 7.88469 15.3495V10.99ZM6.79513 10.99V15.3495C6.79513 15.6504 6.55053 15.895 6.24969 15.895H1.89013C1.58929 15.895 1.34469 15.6504 1.34469 15.3495V10.99C1.34469 10.6891 1.58929 10.4445 1.89013 10.4445H6.24969C6.55053 10.4445 6.79513 10.6891 6.79513 10.99Z"
                fill="#323338"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.6047 2.27001C16.6047 1.36749 15.8722 0.63501 14.9697 0.63501H10.6101C9.70758 0.63501 8.9751 1.36749 8.9751 2.27001V6.62957C8.9751 7.53209 9.70758 8.26457 10.6101 8.26457H14.9697C15.8722 8.26457 16.6047 7.53209 16.6047 6.62957V2.27001ZM15.5151 2.27001V6.62957C15.5151 6.93041 15.2705 7.17501 14.9697 7.17501H10.6101C10.3093 7.17501 10.0647 6.93041 10.0647 6.62957V2.27001C10.0647 1.96917 10.3093 1.72457 10.6101 1.72457H14.9697C15.2705 1.72457 15.5151 1.96917 15.5151 2.27001Z"
                fill="#323338"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.6047 10.99C16.6047 10.0875 15.8722 9.35498 14.9697 9.35498H10.6101C9.70758 9.35498 8.9751 10.0875 8.9751 10.99V15.3495C8.9751 16.2521 9.70758 16.9845 10.6101 16.9845H14.9697C15.8722 16.9845 16.6047 16.2521 16.6047 15.3495V10.99ZM15.5151 10.99V15.3495C15.5151 15.6504 15.2705 15.895 14.9697 15.895H10.6101C10.3093 15.895 10.0647 15.6504 10.0647 15.3495V10.99C10.0647 10.6891 10.3093 10.4445 10.6101 10.4445H14.9697C15.2705 10.4445 15.5151 10.6891 15.5151 10.99Z"
                fill="#323338"
              />
            </svg>
          </span>
          <span>Dashboard</span>
          </Link>
      </li>
      <li className="dropdown proIconStock">
        <a href="javascript:void(0)">
          <span className="menu-icon">
            <svg
              width={18}
              height={17}
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_697_3757"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={18}
                height={17}
              >
                <path
                  d="M16.56 16.3799V1.02988H1.20996V16.3799H16.56Z"
                  fill="white"
                  stroke="white"
                />
              </mask>
              <g mask="url(#mask0_697_3757)">
                <path
                  d="M14.5402 10.6877L16.4213 8.80065L11.4328 3.83294C10.7184 4.51539 9.82967 5.28189 8.91688 5.86276C9.44915 5.17079 10.0799 3.91714 10.5355 2.93937L8.75721 1.16852L3.70988 6.2201C3.2768 6.6535 3.21249 7.3334 3.55663 7.84031L4.72148 9.55639C5.20792 10.273 5.03433 11.2466 4.33032 11.7511C3.40124 12.3849 2.11502 13.2394 1.80967 13.5571C1.19495 14.1718 1.19495 15.1684 1.80967 15.7831C2.4244 16.3978 3.42101 16.3978 4.03573 15.7831C4.40396 15.4387 5.15906 14.2701 5.83915 13.2613C6.34325 12.5564 7.31748 12.3823 8.03452 12.8689L9.74549 14.03C10.2534 14.3747 10.9346 14.3094 11.3678 13.8745L12.2963 12.9276L4.63228 5.29546"
                  stroke="#323338"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.5674 7.10815C12.4615 6.34175 13.0363 5.57534 13.0363 5.57534"
                  stroke="#323338"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </span>
          <span>Production</span>
        </a>
        <ul className="sidebar-submenu">
          <li>
            <Link to="/productions">
              <i className="ri-circle-fill circle-icon text-primary-600 w-auto" />
              My Production{" "}
            </Link>
          </li>
        </ul>
      </li>
      <li className="dropdown">
        <a href="javascript:void(0)">
          <span className="menu-icon">
            <svg
              width={19}
              height={13}
              viewBox="0 0 19 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.0277 4.32342C17.9266 4.24411 17.8052 4.195 17.6774 4.18174C17.5496 4.16848 17.4207 4.19162 17.3055 4.24848L13.2998 6.19685L10.0025 0.358542C9.94312 0.253699 9.85696 0.166494 9.75284 0.105822C9.64872 0.0451502 9.53036 0.0131836 9.40985 0.0131836C9.28934 0.0131836 9.17099 0.0451502 9.06687 0.105822C8.96275 0.166494 8.87658 0.253699 8.81717 0.358542L5.53354 6.17642L1.55504 4.24848C1.43985 4.19162 1.31094 4.16848 1.18316 4.18174C1.05539 4.195 0.933972 4.24411 0.832915 4.32342C0.732135 4.40244 0.655671 4.50827 0.612292 4.62876C0.568914 4.74926 0.560378 4.87954 0.587665 5.00467L2.20904 12.4099C2.24228 12.5648 2.32849 12.7033 2.45283 12.8015C2.57717 12.8998 2.73187 12.9516 2.89029 12.948H15.943C16.1005 12.9519 16.2545 12.901 16.3787 12.8042C16.5029 12.7073 16.5897 12.5703 16.6243 12.4167L18.2865 5.01148C18.3137 4.88404 18.3038 4.75149 18.2579 4.62953C18.212 4.50758 18.1321 4.40134 18.0277 4.32342ZM15.398 11.5855H3.42166L2.22266 6.08104L5.5131 7.68198C5.66941 7.75809 5.8489 7.77155 6.01481 7.71963C6.18073 7.6677 6.32051 7.55431 6.40554 7.40267L9.43029 2.07529L12.4482 7.42311C12.5333 7.57474 12.673 7.68814 12.839 7.74006C13.0049 7.79199 13.1844 7.77852 13.3407 7.70242L16.6515 6.08785L15.398 11.5855Z"
                fill="#323338"
              />
            </svg>
          </span>
          <span>Special Access</span>
        </a>
        <ul className="sidebar-submenu">
          <li>
            <Link to="/projects">
              <i className="ri-circle-fill circle-icon text-primary-600 w-auto" />{" "}
              Projects{" "}
            </Link>
          </li>
          <li>
            <Link to="/clients">
              <i className="ri-circle-fill circle-icon text-primary-600 w-auto" />{" "}
              My Clients
            </Link>
          </li>
        </ul>
      </li>
      <li className="dropdown">
        <a href="javascript:void(0)">
          <span className="menu-icon">
            <svg
              width={22}
              height={22}
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_747_367"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={22}
                height={22}
              >
                <rect width={22} height={22} fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_747_367)">
                <path
                  d="M3.20825 17.8751V15.8373C3.20825 15.3883 3.33017 14.9725 3.574 14.59C3.81784 14.2076 4.14371 13.9136 4.55163 13.7079C5.4576 13.2637 6.37159 12.9304 7.29361 12.7083C8.21562 12.4862 9.1455 12.3751 10.0833 12.3751C10.405 12.3751 10.7268 12.3895 11.0485 12.4184C11.3701 12.4471 11.6918 12.4903 12.0135 12.5479C11.9877 13.3341 12.1467 14.0736 12.4904 14.7665C12.8342 15.4591 13.3228 16.037 13.9562 16.5001V17.8751H3.20825ZM17.2756 20.343L16.053 19.1252V15.3501C15.4555 15.1743 14.9678 14.8384 14.5898 14.3425C14.212 13.8466 14.0231 13.276 14.0231 12.6306C14.0231 11.8444 14.301 11.1734 14.8568 10.6176C15.4126 10.0618 16.0837 9.78391 16.87 9.78391C17.6562 9.78391 18.3258 10.0619 18.8789 10.6178C19.4318 11.174 19.7083 11.8453 19.7083 12.632C19.7083 13.2422 19.537 13.7823 19.1945 14.2524C18.8518 14.7225 18.4148 15.0604 17.8836 15.266L18.8972 16.2796L17.681 17.4988L18.8972 18.7177L17.2756 20.343ZM10.0833 10.8942C9.20096 10.8942 8.4457 10.5801 7.81748 9.95189C7.18911 9.32367 6.87492 8.56841 6.87492 7.68612C6.87492 6.80383 7.18911 6.04849 7.81748 5.42012C8.4457 4.79189 9.20096 4.47778 10.0833 4.47778C10.9655 4.47778 11.7208 4.79189 12.349 5.42012C12.9774 6.04849 13.2916 6.80383 13.2916 7.68612C13.2916 8.56841 12.9774 9.32367 12.349 9.95189C11.7208 10.5801 10.9655 10.8942 10.0833 10.8942ZM16.87 13.0362C17.0946 13.0362 17.2859 12.9557 17.4439 12.7947C17.602 12.6337 17.681 12.4409 17.681 12.2165C17.681 11.9921 17.602 11.8008 17.4439 11.6427C17.2859 11.4846 17.0946 11.4055 16.87 11.4055C16.6456 11.4055 16.4529 11.4846 16.2918 11.6427C16.1308 11.8008 16.0503 11.9921 16.0503 12.2165C16.0503 12.4409 16.1308 12.6337 16.2918 12.7947C16.4529 12.9557 16.6456 13.0362 16.87 13.0362Z"
                  fill="#323338"
                />
              </g>
            </svg>
          </span>
          <span>Employee Access</span>
        </a>
        <ul className="sidebar-submenu">
          <li>
            <Link to="/employee-data">
              <i className="ri-circle-fill circle-icon text-primary-600 w-auto" />
              Employee Data{" "}
            </Link>
          </li>
          {/* <li>
            <Link to="/view-production">
              <i className="ri-circle-fill circle-icon text-primary-600 w-auto" />
              View Production
            </Link>
          </li> */}
          <li>
            <Link to="/view-project">
              <i className="ri-circle-fill circle-icon text-primary-600 w-auto" />
              View Project
            </Link>
          </li>
        </ul>
      </li>
      <li className="dropdown">
        <a href="javascript:void(0)">
          <span className="menu-icon">
            <svg
              width={18}
              height={17}
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.88519 0.42032C8.20861 0.409724 7.53483 0.530283 6.90524 0.778484C6.272 1.02812 5.69503 1.40018 5.20586 1.87351C4.71669 2.34684 4.32431 2.91185 4.05392 3.53657C3.78353 4.16129 3.63993 4.83381 3.62965 5.51446C3.62964 5.51674 3.62964 5.51902 3.62965 5.5213V8.59421C3.62965 8.74909 3.69118 8.89764 3.80069 9.00716C3.9102 9.11668 4.05873 9.17821 4.2136 9.17822C4.36847 9.17821 4.517 9.11668 4.62651 9.00716C4.73602 8.89764 4.79754 8.74909 4.79755 8.59421V5.53043C4.80551 5.00341 4.91666 4.48339 5.12602 3.99968C5.33536 3.51599 5.63914 3.07952 6.01791 2.71302C6.39667 2.34654 6.84378 2.05767 7.33408 1.86438C7.82437 1.6711 8.34686 1.57661 8.87379 1.58606C8.88139 1.58621 8.889 1.58621 8.8966 1.58606C9.42352 1.57661 9.94602 1.6711 10.4363 1.86438C10.9266 2.05767 11.3737 2.34654 11.7525 2.71302C12.1313 3.07952 12.435 3.51597 12.6444 3.99968C12.8537 4.48347 12.9649 5.00346 12.9728 5.53043V8.59421C12.9728 8.74909 13.0344 8.89764 13.1439 9.00716C13.2534 9.11668 13.4019 9.17821 13.5568 9.17822C13.7117 9.17821 13.8602 9.11668 13.9697 9.00716C14.0792 8.89764 14.1407 8.74909 14.1407 8.59421V5.5213C14.1408 5.51902 14.1408 5.51674 14.1407 5.51446C14.1305 4.83375 13.9867 4.16121 13.7165 3.53657C13.4461 2.91187 13.0537 2.34684 12.5645 1.87351C12.0754 1.40018 11.4984 1.02812 10.8651 0.778484C10.2356 0.530283 9.56177 0.409724 8.88519 0.42032Z"
                fill="#323338"
              />
              <path
                d="M2.46181 6.2583C1.9974 6.2583 1.55157 6.4432 1.2232 6.77159C0.894827 7.10001 0.709961 7.54589 0.709961 8.01034V10.3464C0.709961 10.8108 0.894827 11.2567 1.2232 11.5851C1.55157 11.9135 1.9974 12.0984 2.46181 12.0984H3.62971C3.93924 12.0984 4.23658 11.9751 4.45545 11.7562C4.67435 11.5373 4.79761 11.24 4.79761 10.9304V7.42632C4.79761 7.11676 4.67435 6.81939 4.45545 6.6005C4.23658 6.38158 3.93924 6.2583 3.62971 6.2583H2.46181ZM2.46181 7.42632H3.62971V10.9304H2.46181C2.30672 10.9304 2.15861 10.869 2.04894 10.7593C1.93926 10.6496 1.87786 10.5015 1.87786 10.3464V8.01034C1.87786 7.85522 1.93926 7.70711 2.04894 7.59742C2.15861 7.48774 2.30672 7.42632 2.46181 7.42632Z"
                fill="#323338"
              />
              <path
                d="M14.1406 6.25806C13.831 6.25806 13.5337 6.38141 13.3148 6.60025C13.0961 6.8191 12.9727 7.11646 12.9727 7.42608V10.9302C12.9727 11.2398 13.0961 11.5371 13.3148 11.756C13.5337 11.9748 13.831 12.0982 14.1406 12.0982H15.3085C15.7728 12.0982 16.2187 11.9134 16.5471 11.5849C16.8753 11.2565 17.0603 10.8107 17.0603 10.3461V8.01009C17.0603 7.54558 16.8753 7.09973 16.5471 6.77135C16.2187 6.44287 15.7728 6.25806 15.3085 6.25806H14.1406ZM14.1406 7.42608H15.3085C15.4635 7.42608 15.6118 7.48758 15.7213 7.59718C15.831 7.7069 15.8924 7.85504 15.8924 8.01009V10.3461C15.8924 10.5012 15.831 10.6493 15.7213 10.7591C15.6118 10.8687 15.4635 10.9302 15.3085 10.9302H14.1406V7.42608Z"
                fill="#323338"
              />
              <path
                d="M13.5567 9.17822C13.4018 9.17823 13.2533 9.23976 13.1438 9.34928C13.0343 9.4588 12.9728 9.60735 12.9728 9.76223V12.3903C12.9728 12.8551 12.7883 13.3003 12.4595 13.629C12.1308 13.9578 11.6858 14.1423 11.2209 14.1423C11.066 14.1423 10.9175 14.2039 10.808 14.3134C10.6985 14.4229 10.637 14.5714 10.637 14.7263C10.637 14.8812 10.6985 15.0298 10.808 15.1393C10.9175 15.2488 11.066 15.3103 11.2209 15.3103C11.995 15.3103 12.7378 15.0023 13.2853 14.4549C13.8327 13.9074 14.1407 13.1645 14.1407 12.3903V9.76223C14.1407 9.60735 14.0791 9.4588 13.9696 9.34928C13.8601 9.23976 13.7116 9.17823 13.5567 9.17822Z"
                fill="#323338"
              />
              <path
                d="M8.00916 12.6821C7.46732 12.6821 6.9461 12.8967 6.56297 13.2798C6.17978 13.663 5.96533 14.1843 5.96533 14.7262C5.96533 15.2681 6.17978 15.7893 6.56297 16.1725C6.9461 16.5557 7.46732 16.7702 8.00916 16.7702H9.761C10.3028 16.7702 10.8241 16.5557 11.2072 16.1725C11.5904 15.7893 11.8048 15.2681 11.8048 14.7262C11.8048 14.1843 11.5904 13.663 11.2072 13.2798C10.8241 12.8967 10.3028 12.6821 9.761 12.6821H8.00916ZM8.00916 13.8502H9.761C9.99353 13.8502 10.217 13.9412 10.3814 14.1057C10.5458 14.27 10.6369 14.4937 10.6369 14.7262C10.6369 14.9587 10.5458 15.1823 10.3814 15.3467C10.217 15.5111 9.99353 15.6022 9.761 15.6022H8.00916C7.77663 15.6022 7.55314 15.5111 7.38871 15.3467C7.22434 15.1823 7.13323 14.9587 7.13323 14.7262C7.13323 14.4937 7.22434 14.27 7.38871 14.1057C7.55314 13.9412 7.77663 13.8502 8.00916 13.8502Z"
                fill="#323338"
              />
            </svg>
          </span>
          <span>Help</span>
        </a>
        <ul className="sidebar-submenu">
          <li>
          <Link to="/wellbeing-officer">
              <i className="ri-circle-fill circle-icon text-primary-600 w-auto" />
              Wellbeing Officer
              </Link>
            <Link to="/HR4You">
              <i className="ri-circle-fill circle-icon text-primary-600 w-auto" />
              HR4You
              </Link>
            <Link to="/grievance">
              <i className="ri-circle-fill circle-icon text-primary-600 w-auto" />
              Grievance
            </Link>
          </li>
        </ul>
      </li>
    </ul>
    <ul className="mt-auto pt-5">
      <li className="sidebar-menu-group-title emailLftBr">
        <span className="d-block d-flex align-items-center gap-1">
          <img
            src="/assets/images/left-sidebar/email-icon.svg"
            className="img-fluid"
          />
          info@acadecraft.com
        </span>{" "}
        All Rights Reserved by Acadecraft | Copyright © 2011-2024{" "}
      </li>
    </ul>
  </div>
</aside>

    </>
  );
};
export default LeftNavBar;
