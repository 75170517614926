import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const ClientProductionPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    totalDurations: PropTypes.string,
});

export const ClientProductionDefaultProps = { 
    totalDurations: '',
    _id: undefined
};

export const ClientProductionYupSchema = Yup.object().shape({ 
    totalDurations: Yup.string().required('Required'),
});

export default {
    ClientProductionDefaultProps,
    ClientProductionPropTypes,
    ClientProductionYupSchema,
};
