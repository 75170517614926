import React, { useEffect, useState } from 'react';
import FilterPopup from './FilterPopup';
import ManageColumn from './ManageColumn';
import EmployeeTabs from '../EmployeeTabs';
import { productionSearchAPI } from 'src/api/ProductionApi';
import moment from 'moment'
import { Link, useParams } from 'react-router-dom';
import DatePickerComponent from 'src/components/DatePickerComponent';

const EmployeeProductionContainer = () => {

  const [production, setProduction] = useState([])
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [search, setSearch] = useState('');
  const {userId} = useParams();




  const getProductionSearch = async () => {
    let body = {
      pageLength: 100,
      where: {
        startDate: startDate || null,
        endDate: endDate || null,
        userId: userId
      }
    }
    try {
      const resp = await productionSearchAPI(body)
      console.log('response from the production api', resp?.data?.data?.data);
      const filteredData = resp.data.data.data.filter(user => {
        const userDate = new Date(user.ts);
        const isWithinDateRange = (!startDate || userDate >= new Date(startDate)) && (!endDate || userDate <= new Date(endDate));
        const matchesSearch = `${user.taskId?.taskName}`.toLowerCase().includes(search.toLowerCase());


        // Debugging: Log each user's data and the filter conditions


        return isWithinDateRange && matchesSearch;
      });
      setProduction(filteredData)

    } catch (error) {
      console.log('error from the production api', error);
    }
  }

  useEffect(() => {
    getProductionSearch()
  }, [search, startDate, endDate,])

  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setStartDate(selectedDates[0]);
      setEndDate(selectedDates[1]);
    } else {
      setStartDate('');
      setEndDate('');
    }
  }



  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
               <Link
                    to="/employee-data">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width={30}
                    height={30}
                  >
                    <g data-name="20-Arrow Left">
                      <path
                        fill="#0073ea"
                        d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"
                      />
                      <path
                        fill="#0073ea"
                        d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"
                      />
                    </g>
                  </svg>
              </Link>{" "}
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="#"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">View Production</li>
              </ul>
              {/* <EmployeeTabs /> */}
              <div className="d-flex gap-2 align-items-center py-16">
                {/* Search start */}
                <div className="search">
                  <span className="searchBtn position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
                      ></path>
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M338.29 338.29L448 448"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control radius-8"
                    id="search"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}
                  />
                </div>
                {/* Search End */}
                {/* Date Range piker start  */}
                <DatePickerComponent onDateRangeChange={handleDateRangeChange} />
                {/* <div className="datepker position-relative">
                  <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <iconify-icon
                      icon="solar:calendar-linear"
                      className="icon text-lg"
                    />
                  </span>
                  <input
                    className="form-control radius-8 bg-base"
                    type="date"
                    id="flat"
                    placeholder="Date"
                  />
                </div> */}
                {/* Date Range piker end  */}
                {/* Filter btn start */}
                {/* <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#filterRightPopup"
                    aria-controls="filterRightPopup"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 radius-8 px-20 py-9 text-sm d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
                      />
                    </svg>{" "}
                    Filter
                  </button>
                </div> */}
                {/* Filter btn end */}
                {/* Manage Column btn start */}
                {/* <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#manageColRightPopup"
                    aria-controls="manageColRightPopup"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 text-sm radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      fill="currentColor"
                      className="bi bi-columns"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm8.5 0v8H15V2zm0 9v3H15v-3zm-1-9H1v3h6.5zM1 14h6.5V6H1z" />
                    </svg>{" "}
                    Manage Column
                  </button>
                </div> */}
                {/* Manage Column btn end */}
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-menu-app-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h2A1.5 1.5 0 0 1 5 1.5v2A1.5 1.5 0 0 1 3.5 5h-2A1.5 1.5 0 0 1 0 3.5zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5" />
                  </svg>{" "}
                  Production
                </h6>
              </div>
              <div
                className="table-responsive table-container"
                id="table-container"
              >
                <table className="table basic-border-table mb-0 table-hover">
                  <thead>
                    <tr className="dnd-moved">
                      <th className="w30px dragablefalse" draggable="false">
                        <input className="form-check-input" type="checkbox" />{" "}
                      </th>
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th>Date </th>
                      <th>Project Code </th>
                      <th>Task Name </th>
                      <th className="text-center">Number </th>
                      <th className="text-center">Item </th>
                      <th className="text-center">Unit </th>
                      <th className="text-center">Cost </th>
                    </tr>
                  </thead>
                  {production?.map((production, index) => (
                    <tbody id="table-body">
                      <tr className="dnd-moved">
                        <td draggable="false">
                          <input className="form-check-input" type="checkbox" />
                        </td>
                        <td draggable="false">
                          <label className="form-check-label">{index + 1}</label>
                        </td>
                        <td>{moment(production.date).format('DD-MM-YYYY')}</td>
                        <td>
                          <div className="d-flex align-items-center gap-2 fw-medium text-primary-600">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-stack"
                              viewBox="0 0 16 16"
                            >
                              <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"></path>
                              <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"></path>
                            </svg>{" "}
                            {production?.clientId?.ClientProjectCode}
                          </div>
                        </td>
                        <td>{production?.taskId?.taskName}</td>
                        <td className="text-center">
                          <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill="#02BC7D"
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                              ></path>
                            </svg>{" "}
                            {production?.totalDurations}
                          </div>
                        </td>
                        <td className="text-center">
                          <button
                            style={{
                              backgroundColor:
                                production?.taskId?.item === 'Daily Target'
                                  ? 'lightgreen'
                                  : production?.taskId?.item === 'Bound'
                                    ? 'purple'
                                    : production?.taskId?.item === 'Open'
                                      ? 'orange'
                                      : 'transparent',
                            }}
                            className="btn"
                          >
                            {production?.taskId?.item}
                          </button>
                        </td>
                        <td className="text-center">
                          <button
                            style={{
                              backgroundColor:
                                production?.taskId?.unit === 'Per Day'
                                  ? 'lightgreen'
                                  : production?.taskId?.unit === 'Min'
                                    ? 'purple' : 'transparent'


                            }}
                            className="btn"
                          >
                            {production?.taskId?.unit}
                          </button>{" "}
                        </td>
                        <td className="text-center">
                          <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                            ₹ {production?.cost||0}
                          </span>
                        </td>
                      </tr>

                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FilterPopup />
      <ManageColumn />
    </>
  )
}

export default EmployeeProductionContainer