import { shallowEqual, useSelector } from "react-redux"
import { formatDate, formatTime } from "src/utils/utils";
import { useDispatch } from 'react-redux';

const ProductionList=()=>{
    // const {} = props;
    const dispatch = useDispatch();
 
   const {search} = useSelector((state) => state.ProductionReducer,shallowEqual);
   const handleComment = (production) => {
        dispatch({ type: "PRODUCTION_CURRENT_PRODUCTION", data: production });
     };
     const handleFileDownload = (production) => {
        console.log('production',production.files)
        production?.files.forEach(file => {
          const a = document.createElement('a');
          a.href = file.url;
          a.target = '_blank'; // Open in a new tab
          a.click();
        });
      };
 return (
    <>
    {
        search?.data?.map((item,index)=>{
            const productionManager = (item?.productionManagers)?item?.productionManagers?.firstName + " " + item?.productionManagers?.lastName:"NA";
            const status = item?.totalDurations<=(item.approvedDurations+item.rejectedDurations)?"Approved":"Pending";
            return(
                <tr key={item._id}>
                    <td>
                        <label className="form-check-label">{index+1}</label>
                    </td>
                    <td>{formatDate(item?.date)}</td>                    
                    <td>{formatTime(item?.ts)}</td>                    
                    <td>
                        <div className="fw-medium">{item?.clientId?.ClientProjectCode}</div>
                    </td>
                    <td className="text-center">{item?.projectId?.ProjectSubCode}</td>
                    <td className="text-center">{item?.totalDurations}</td>
                    <td className="text-center bg-warning-600">
                        <span className="d-flex border-0 align-items-center gap-4 justify-content-between text-white fw-semibold py-2 w-100">
                        {status}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={22}
                            fill="currentColor"
                            className="bi bi-clock"
                            viewBox="0 0 16 16"
                        >
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"></path>
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"></path>
                        </svg>{" "}
                        </span>
                    </td>
                      <td className="text-center">{productionManager}</td>
                      <td className="text-center">
                        {
                            (item?.files?.length>0)?
                            <button className="d-flex align-items-center gap-2 text-primary-600"
                            onClick={()=>{handleFileDownload(item)}}
                            >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={22}
                                height={22}
                                fill="currentColor"
                                className="bi bi-file-earmark-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z" />
                            </svg>{" "}
                            View
                            </button>
                            :"NA"
                        }
                            
                        </td>
                         <td className="text-center">
                                <button
                                className="text-primary-900"
                                data-bs-toggle="modal"
                                data-bs-target="#CommentModal"
                                onClick={() => handleComment(item)}
                                >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={22}
                                    height={22}
                                    fill="currentColor"
                                    className="bi bi-eye-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                </svg>
                                </button>
                        </td>
                        <td className="text-center">{item?.approvedDurations}</td>
                    </tr>
                )
            })
        }
        {/* <tr className="dnd-moved">                         
        <td draggable="false">
            <label className="form-check-label">01</label>
        </td>
        <td>28-Jun-2024</td>
        <td>06:30 pm</td>
        <td>
            <div className="fw-medium">Project_316</div>
        </td>
        <td className="text-center">A</td>
        <td className="text-center">2</td>
        <td className="text-center bg-warning-600">
            <span className="d-flex border-0 align-items-center gap-4 justify-content-between text-white fw-semibold py-2 w-100">
            Pending
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                fill="currentColor"
                className="bi bi-clock"
                viewBox="0 0 16 16"
            >
                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"></path>
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"></path>
            </svg>{" "}
            </span>
        </td>
        <td>
            <div className="d-flex align-items-center gap-2">
            <span
                className="userimg"
                style={{
                backgroundColor: "rgb(0, 182, 185)",
                color: "white"
                }}
            />
            <div className="flex-grow-1">
                <h6 className="text-md mb-0 fw-medium text-neutral-900">
                Sandeep Dubey
                </h6>
            </div>
            </div>
        </td>
        <td className="text-center">
            <button className="d-flex align-items-center gap-2 text-primary-600">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                fill="currentColor"
                className="bi bi-file-earmark-fill"
                viewBox="0 0 16 16"
            >
                <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z" />
            </svg>{" "}
            View
            </button>
        </td>
        <td className="text-center">
            <button
            className="text-primary-900"
            data-bs-toggle="modal"
            data-bs-target="#CommentModal"
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                fill="currentColor"
                className="bi bi-eye-fill"
                viewBox="0 0 16 16"
            >
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
            </svg>
            </button>
        </td>
        <td className="text-center">2</td>
        </tr>
        <tr className="dnd-moved">                        
        <td draggable="false">
            <label className="form-check-label">03</label>
        </td>
        <td>28-Jun-2024</td>
        <td>06:30 pm</td>
        <td>
            <div className="fw-medium">Project_316</div>
        </td>
        <td className="text-center">A</td>
        <td className="text-center">2</td>
        <td className="text-center bg-danger-400">
            <button className="d-flex justify-contant-between py-2 align-items-center gap-4 justify-content-center text-white fw-semibold">
            Rejected
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                fill="currentColor"
                className="bi bi-x-circle-fill"
                viewBox="0 0 16 16"
            >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"></path>
            </svg>
            </button>
        </td>
        <td>
            <div className="d-flex align-items-center gap-2">
            <span
                className="userimg"
                style={{
                backgroundColor: "rgb(0, 182, 185)",
                color: "white"
                }}
            />
            <div className="flex-grow-1">
                <h6 className="text-md mb-0 fw-medium text-neutral-900">
                Sandeep Dubey
                </h6>
            </div>
            </div>
        </td>
        <td className="text-center">
            <button className="d-flex align-items-center gap-2 text-primary-600">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                fill="currentColor"
                className="bi bi-file-earmark-fill"
                viewBox="0 0 16 16"
            >
                <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z" />
            </svg>{" "}
            View
            </button>
        </td>
        <td className="text-center">
            <button
            className="text-primary-900"
            data-bs-toggle="modal"
            data-bs-target="#CommentModal"
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                fill="currentColor"
                className="bi bi-eye-fill"
                viewBox="0 0 16 16"
            >
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
            </svg>
            </button>
        </td>
        <td className="text-center">2</td>
        </tr>
        <tr className="dnd-moved">
        
        <td draggable="false">
            <label className="form-check-label">02</label>
        </td>
        <td>28-Jun-2024</td>
        <td>06:30 pm</td>
        <td>
            <div className="fw-medium">Project_316</div>
        </td>
        <td className="text-center">A</td>
        <td className="text-center">2</td>
        <td className="text-center bg-success-400">
            <span className="d-flex justifu-content-between py-2 align-items-center gap-4 justify-content-center text-white fw-semibold">
            Approved
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                fill="currentColor"
                className="bi bi-check-circle"
                viewBox="0 0 16 16"
            >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
            </svg>
            </span>
        </td>
        <td>
            <div className="d-flex align-items-center gap-2">
            <span
                className="userimg"
                style={{
                backgroundColor: "rgb(0, 182, 185)",
                color: "white"
                }}
            />
            <div className="flex-grow-1">
                <h6 className="text-md mb-0 fw-medium text-neutral-900">
                Sandeep Dubey
                </h6>
            </div>
            </div>
        </td>
        <td className="text-center">
            <button className="d-flex align-items-center gap-2 text-primary-600">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                fill="currentColor"
                className="bi bi-file-earmark-fill"
                viewBox="0 0 16 16"
            >
                <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z" />
            </svg>{" "}
            View
            </button>
        </td>
        <td className="text-center">
            <button
            className="text-primary-900"
            data-bs-toggle="modal"
            data-bs-target="#CommentModal"
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                fill="currentColor"
                className="bi bi-eye-fill"
                viewBox="0 0 16 16"
            >
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
            </svg>
            </button>
        </td>
        <td className="text-center">2</td>
        </tr> */}
    </>
 )
}
export default ProductionList