import { Link } from 'react-router-dom';
const EmployeeTabs=()=>{
    return (
        <div className="tabsAllPages">
  <nav className="tabs">
    <ul className="-primary nav my-16">
      <li>
         <Link to="/skill" >
            Skill 
        </Link>
      </li>
      <li>
        <a href="javascript:void(0)">Personal</a>
      </li>
      <li>
        <a href="javascript:void(0)">Family Detail</a>
      </li>
      <li>
        <a href="javascript:void(0)">Education</a>
      </li>
      <li>
        <a href="javascript:void(0)">Profession</a>
      </li>
      <li>
        <a href="javascript:void(0)">KYC</a>
      </li>
      <li>
        <a href="javascript:void(0)">Bank Details</a>
      </li>
      <li>
        <a href="javascript:void(0)">Department</a>
      </li>
      <li>
        <Link to="/view-production" >
           Production 
        </Link>
      </li>
      <li>
      <Link to="/view-project" >
           Project 
      </Link>
         
      </li>
    </ul>
  </nav>
</div>

    )
}
export default EmployeeTabs