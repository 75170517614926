import React, { useState, useEffect } from 'react';
import TopTabs from "../TopTabs"
import ModuleFilter from "./FIlter"
import ManageModuleColum from "./ManageColoum"
import { useParams } from 'react-router-dom';
import { moduleProjectSearchAPI } from 'src/api/ReportsAPI';
import BackProjectButton from 'src/components/BackProjectButton';


const ModuleWiseContainer = () => {

  const { projectId } = useParams();
  const [module, setModule] = useState([])
  const [totalcost, setTotalCost] = useState()
  const [totalDuration, setTotalDuration] = useState()
  const [search, setSearch] = useState('');

  const getProjectModuleData = async () => {
    let body = {
      pageLength: 100
    };

    try {
      const resp = await moduleProjectSearchAPI({ projectId, body });
      console.log('response from project Task', resp?.data?.data);

      const skillsData = resp?.data?.data || [];

      // Filter skills based on departmentName
      const filterSkillsData = skillsData.filter(item =>
        item?.moduleName?.toLowerCase().includes(search.toLowerCase())
      );

      // Calculate total duration and total cost for filtered data
      const totals = filterSkillsData.reduce((acc, item) => {
        acc.totalDuration += item.approvedDurations || 0;
        acc.totalCost += item.cost || 0;
        return acc;
      }, { totalDuration: 0, totalCost: 0 });

      console.log('Total Duration:', totals.totalDuration);
      console.log('Total Cost:', totals.totalCost);

      // Set the filtered skills and totals in state
      setModule(filterSkillsData);
      setTotalDuration(totals.totalDuration);
      setTotalCost(totals.totalCost);

    } catch (error) {
      console.log('error from project Task', error);
    }
  };

  useEffect(() => {
    getProjectModuleData();
  }, [search]);


  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
              <BackProjectButton/>
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="index-2.html"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Module</li>
              </ul>
              <TopTabs />
              <div className="d-flex gap-2 align-items-center py-16">
                {/* Search start */}
                <div className="search">
                  <span className="searchBtn position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
                      ></path>
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M338.29 338.29L448 448"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control radius-8"
                    id="search"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}
                  />
                </div>
                {/* Search End */}
                {/* Date Range piker start  */}
                {/* <div className="datepker position-relative">
                  <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <iconify-icon
                      icon="solar:calendar-linear"
                      className="icon text-lg"
                    />
                  </span>
                  <input
                    className="form-control radius-8 bg-base"
                    type="date"
                    id="flat"
                    placeholder="Date"
                  />
                </div> */}
                {/* Date Range piker end  */}
                {/* Manage Column btn start */}
                {/* <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#manageColRightPopup"
                    aria-controls="manageColRightPopup"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 text-sm radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      fill="currentColor"
                      className="bi bi-columns"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm8.5 0v8H15V2zm0 9v3H15v-3zm-1-9H1v3h6.5zM1 14h6.5V6H1z" />
                    </svg>{" "}
                    Manage Column
                  </button>
                </div> */}
                {/* Manage Column btn end */}
                {/* Filter btn start */}
                {/* <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#filterRightPopup"
                    aria-controls="filterRightPopup"
                    className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
                      />
                    </svg>{" "}
                    Filter
                  </button>
                </div> */}
                {/* Filter btn end */}
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-stack"
                    viewBox="0 0 16 16"
                  >
                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
                  </svg>{" "}
                  Project Code_315B
                </h6>
              </div>
              <div className="table-responsive table-container" id="table-container">
                <table className="table basic-border-table mb-0 table-hover">
                  <thead>
                    <tr className="dnd-moved">
                      <th className="w30px dragablefalse" draggable="false">
                        <input className="form-check-input" type="checkbox" />{" "}
                      </th>
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th>Module Name </th>
                      <th className="text-center">Type </th>
                      <th className="text-center">No. of Hours </th>
                      <th className="text-center">Cost</th>
                    </tr>
                  </thead>
                  {module?.map((item, index) => (

                    <tbody>
                      <tr className="dnd-moved">
                        <td>
                          <input className="form-check-input" type="checkbox" />
                        </td>
                        <td draggable="false">
                          <label className="form-check-label">{index + 1}</label>
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="btn rounded-pill btn-outline-info-600 d-flex align-items-center justify-content-center accordion-toggle togalnewscl p-0 me-2 collapsed"
                              draggable="false"
                              data-bs-toggle="collapse"
                              data-bs-target="#demo1"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-caret-down-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                              </svg>
                            </button>
                            <div className="d-flex align-items-center gap-2 fw-medium text-primary-600">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                className="bi bi-stack"
                                viewBox="0 0 16 16"
                              >
                                <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"></path>
                                <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"></path>
                              </svg>{" "}
                              {item?.moduleName}
                            </div>
                          </div>
                        </td>
                        <td className="text-center">0</td>
                        <td>
                          <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-check-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill="#02BC7D"
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                              />
                            </svg>{" "}
                            {item?.approvedDurations}
                          </div>
                        </td>
                        <td className="text-center">
                          <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                            ₹ {item?.cost}
                          </span>
                        </td>
                      </tr>
                      {item?.taskDetails?.map((item) => (
                        <tr className="bgliggry">
                          <td colSpan={2} className="hiddenRow border-0 p-0 bgliggry" />
                          <td colSpan={4} className="hiddenRow border-0 p-0 bgliggry">
                            <div
                              id="demo1"
                              className="accordion-collapse collapse uldropdn"
                            >
                              <ul>
                                <li />
                                <li>{item?.taskName}</li>
                                <li className="text-center">
                                  <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={16}
                                      height={16}
                                      fill="currentColor"
                                      className="bi bi-check-circle-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fill="#D0D4E4"
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                                      ></path>
                                    </svg>{" "}
                                    {item?.approvedDurations}
                                  </div>
                                </li>
                                <li className="text-center">₹{item?.cost}</li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}




                    </tbody>
                  ))}
                  <tfoot>
                    <tr>
                      <td colSpan={2} />
                      <td colSpan={2} className="fw-bold">
                        Total
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">{totalDuration}</span> Total Hours
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">₹{totalcost}</span> Total Cost
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModuleFilter />
      <ManageModuleColum />
    </>
  )
}
export default ModuleWiseContainer