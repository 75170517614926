const ClientDeliveryFilter=()=>{
return (
    <>
    <div
  className="offcanvas offcanvas-end filterSecPopup"
  id="filterRightPopup"
  aria-labelledby="filterRightLayout"
>
  <div className="offcanvas-header">
    <h5 className="offcanvas-title" id="filterRightLayout">
      Filter
    </h5>
    <button
      type="button"
      className="btn-close d-none"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    />
  </div>
  <div className="offcanvas-body pt-0">
    <div className="assignPopoHeight">
      <form className="AllFilter_Select" action="/action_page.php">
        <input className="form-check-input" type="checkbox" id="allFilter" />
        <label className="allFilter" htmlFor="allFilter">
          All Filter <span>3 Select</span>
        </label>
      </form>
      <div className="selectSec filterStyleSec">
        <label htmlFor="">Department</label>
        <select id="departmentSec">
          <option value="">Select Department</option>
          <option value={1}>Awesome</option>
          <option value={2}>Beast</option>
          <option value={3}>Compatible</option>
          <option value={4}>Thomas Edison</option>
          <option value={5}>Nikola</option>
        </select>
      </div>
      <div className="selectSec filterStyleSec">
        <label htmlFor="">Cadre</label>
        <select id="cadreMenuSec">
          <option value="">Select Cadre</option>
          <option value={1}>Awesome</option>
          <option value={2}>Beast</option>
          <option value={3}>Compatible</option>
          <option value={4}>Thomas Edison</option>
          <option value={5}>Nikola</option>
        </select>
      </div>
      <div className="selectSec filterStyleSec">
        <label htmlFor="">Uploaded by</label>
        <select id="uploadedMenuSec">
          <option value="">Select User</option>
          <option value={1}>Awesome</option>
          <option value={2}>Beast</option>
          <option value={3}>Compatible</option>
          <option value={4}>Thomas Edison</option>
          <option value={5}>Nikola</option>
        </select>
      </div>
      <div className="selectSec">
        <label htmlFor="">Select Actual Date</label>
        <div className="col-md-12 mb-4">
          {/* <label for="startDate" class="form-label fw-semibold text-primary-light text-sm mb-8"></label> */}
          <div className=" position-relative">
            <input
              className="form-control radius-8 bg-base"
              id="startDate"
              type="text"
              placeholder="From  DDMMYY"
            />
            <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
              <iconify-icon
                icon="solar:calendar-linear"
                className="icon text-lg"
              />
            </span>
          </div>
        </div>
        <div className="col-md-12">
          {/* <label for="endDate" class="form-label fw-semibold text-primary-light text-sm mb-8">End Date
                  </label> */}
          <div className=" position-relative">
            <input
              className="form-control radius-8 bg-base"
              id="endDate"
              type="text"
              placeholder="To  DDMMYY"
            />
            <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
              <iconify-icon
                icon="solar:calendar-linear"
                className="icon text-lg"
              />
            </span>
          </div>
        </div>
        {/* Date picker css */}
      </div>
      <div className="selectSec">
        <label htmlFor="">Delivery Date</label>
        <div className="col-md-12 mb-4">
          {/* <label for="startDate" class="form-label fw-semibold text-primary-light text-sm mb-8"></label> */}
          <div className=" position-relative">
            <input
              className="form-control radius-8 bg-base"
              id="startDate"
              type="text"
              placeholder="From  DDMMYY"
            />
            <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
              <iconify-icon
                icon="solar:calendar-linear"
                className="icon text-lg"
              />
            </span>
          </div>
        </div>
        <div className="col-md-12 mb-20">
          {/* <label for="endDate" class="form-label fw-semibold text-primary-light text-sm mb-8">End Date
                  </label> */}
          <div className=" position-relative">
            <input
              className="form-control radius-8 bg-base"
              id="endDate"
              type="text"
              placeholder="To  DDMMYY"
            />
            <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
              <iconify-icon
                icon="solar:calendar-linear"
                className="icon text-lg"
              />
            </span>
          </div>
        </div>
        {/* Date picker css */}
      </div>
      <div className="selectSec pt-0">
        <label htmlFor="">Count</label>
        <div className="costSec">
          <div className="costinputSec">
            {/* <label for="">₹</label> */}
            <input type="number" min={0} defaultValue={0} />
          </div>
          <select className="slectIconsShow">
            <option value="Above">Above</option>
            <option value="">15</option>
            <option value="">75</option>
            <option value="">78</option>
            <option value="">85</option>
            <option value="">03</option>
            <option value="">10</option>
          </select>
        </div>
      </div>
    </div>
    <div className="">
      <button
        type="button"
        className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
      >
        Save Filter
      </button>
    </div>
  </div>
</div>

    </>
)
}
export default ClientDeliveryFilter