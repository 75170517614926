import { useState } from "react"

const SearchInput=({onChange})=>{
    const [isOpen,setIsOpen]=useState(false)
    const handleClick=()=>{
        setIsOpen(!isOpen)
    }
    return(
        <div className="search">
        <span className="searchBtn position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeMiterlimit={10}
              strokeWidth={32}
              d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
            ></path>
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeMiterlimit={10}
              strokeWidth={32}
              d="M338.29 338.29L448 448"
            ></path>
          </svg>
        </span>
        <input
          type="text"
          className="form-control radius-8 search-input"
          id="search"
          placeholder="Search"
          onClick={handleClick}
          onChange={onChange}
        />
      </div>
    )
}
export default SearchInput