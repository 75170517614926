import { useSelector,shallowEqual } from "react-redux"
import ProjectTree from "./ProjectsTree"
import { useState } from "react"

const ProjectTreeContainer=(props)=>{
  const [isOpenProj,setIsOpenProject] = useState(false)
  const {assignedProjects} = useSelector(state=>state.ProductionReducer,shallowEqual)
    return (
        <nav className="acnav" role="navigation">
        {/* start level 1 */}
        <ul className="acnav__list acnav__list--level1">
          {/* start group 1 */}
          <li className={`has-children ${isOpenProj ? 'is-open' : ''}`}
          onClick={() => {setIsOpenProject(!isOpenProj)}}
          >
            <div className="acnav__label mainHeadPro">Select Project</div>
            {/* start level 2 */} 
            <ul className="acnav__list acnav__list--level2 setProducHeightSet">           
            {
              assignedProjects.map((item,index)=>{
                return(              
                 <ProjectTree  key={index} item={item}/>
                )
              })
            }
            </ul>
            {/* end level 2 */}
          </li>
          {/* end group 1 */}
        </ul>
        {/* end level 1 */}
      </nav>
    )
}
export default ProjectTreeContainer