import { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';

const TopTabs = () => {
    const { projectId } = useParams();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('skill');

    useEffect(() => {
        // Check the current path and set the active tab accordingly
        if (location.pathname.includes('/skill')) {
            setActiveTab('skill');
        } else if (location.pathname.includes('/module')) {
            setActiveTab('module');
        }
    }, [location.pathname]);

    const TabsConst = [
        { name: "Skill", url: `/projects/view/skill/${projectId}` },
        { name: "Task", url: `/projects/view/task/${projectId}` },
        { name: "Individual", url: `/projects/view/individual/${projectId}` },
        { name: "Client Delivery", url: `/projects/view/client-delivery/${projectId}` },
        { name: "Module", url: `/projects/view/module/${projectId}` },
        // { name: "Vendor Production", url: `/projects/view/vendor-production/${projectId}` },
        { name: "Production", url: `/projects/view/production/${projectId}` },
        // { name: "Project Documentation", url: `/projects/view/project-documentation/${projectId}` },
        // { name: "Vendor Payment", url: `/projects/view/vendor-payment/${projectId}` },
        { name: "P&L", url: `/projects/view/p-and-l/${projectId}` }
    ];

    return (
        <div className="tabsAllPages">
            <nav className="tabs">
                <ul className="-primary nav my-16">
                    {TabsConst.map((tab) => (
                        <li key={tab.name}>
                            <Link
                                to={tab.url}
                                className={activeTab === tab.name.toLowerCase() ? 'active' : ''}
                            >
                                {tab.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default TopTabs;
