import { all } from 'redux-saga/effects'; 
import AppSaga from './containers/app-container/saga/AppSaga'; 
import HomeSaga from './containers/home-container/saga/HomeSaga';
import AuthSaga from './containers/home-container/saga/AuthSaga'; 
import ClientSaga from './containers/client-container/saga/ClientSaga'; 
import ProductionSaga from './containers/production-container/saga/ProductionSaga'; 
import ProjectSaga from './containers/project-container/saga/ProjectSaga'; 
import TaskSaga from './containers/project-container/Task/saga/TaskSaga'; 
import ModuleSaga from './containers/project-container/Module/saga/ModuleSaga'; 
import ClientProductionSaga from './containers/project-container/View/ClientDeliveryWise/saga/ClientProductionSaga';
 

export default function* sagas() {
    yield all([
        ...HomeSaga,
        ...AuthSaga, 
        ...AppSaga,
        ...ProjectSaga,
        ...ClientSaga,
        ...TaskSaga,
        ...ModuleSaga,
        ...ProductionSaga,
        ...ClientProductionSaga
    ]);
}
