import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react"
import { shallowEqual, useSelector } from "react-redux"
import config from "src/config/index";
import { toast } from 'react-toastify';
import { postRequest, putRequest } from "src/utils/RequestUtil";
const AssignTask=({users,getUserList,getClientList})=>{ 
  const {_id:userId} = useSelector(state=>state.auth?.user,shallowEqual);
  const {usersForProjectAssign,currentSelectedProjectId,projectCode,projectSub} = useSelector(state=>state.ClientReducer,shallowEqual)
  const handleUserSearch = useCallback(
    debounce((searchKey, getUserList) => {
      const whereClauseKeysPaths = ['firstName', 'lastName', 'email'];
      const whereWithOrCondition = {
        $or: whereClauseKeysPaths.map((keyPath) => ({ 
          [keyPath]: {
            $regex: searchKey,
            $options: 'i',
          },
        })),
      }; 
      getUserList(whereWithOrCondition);
    }, 300),
    []
  );
  const [usersForProjectAssignState,setUsersForProjectAssignState] = useState(usersForProjectAssign)
  const handleUserToggle = async (userId) => {
    setUsersForProjectAssignState((prevState) => {
      // Check if the userId is already in the array
      if (prevState.includes(userId)) {
        // Remove the userId if it exists
        return prevState.filter((id) => id !== userId);
      } else {
        // Add the userId if it doesn't exist
        return [...prevState, userId];
      }
    });
  };
  const handleSubmit = async() => {
   await postRequest({
      url: `${config.baseUrl}project/saveAssignees`,
      data:{
        userIds:usersForProjectAssignState,
        projectId:currentSelectedProjectId,
        by:userId
      }
    })
    await getClientList()
    toast("User Assigned Successfully");
  };
  useEffect(() => {
    setUsersForProjectAssignState(usersForProjectAssign)
  }, [usersForProjectAssign])
    return (
        <div
        className="offcanvas offcanvas-end filterSecPopup"
        id="assignTaskPopup"
        aria-labelledby="assignTaskPopupSec"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="filteraddClientSec">
            Assign Task To Users for project: {`${projectCode}-(${projectSub})`}
          </h5>
        </div>
        <div className="offcanvas-body pt-0">
          <div className="assignPopoHeight">
            <div className="mangeSearchSec">
              <input
                type="text"
                className="managecolsSearch"
                placeholder="Search users by name or email"
                onChange={(e)=>handleUserSearch(e.target.value,getUserList)} 
              />
              <svg
                width={17}
                height={16}
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.3215 14.9883L12.0198 10.6866C12.9629 9.55576 13.5321 8.10274 13.5321 6.51846C13.5321 2.92412 10.608 0 7.01362 0C3.41926 0 0.495117 2.92412 0.495117 6.51846C0.495117 10.1128 3.41926 13.0369 7.01362 13.0369C8.59791 13.0369 10.0509 12.4677 11.1818 11.5246L15.4835 15.8264C15.5992 15.9421 15.7509 16 15.9025 16C16.054 16 16.2057 15.9421 16.3215 15.8264C16.553 15.5948 16.553 15.2197 16.3215 14.9883ZM1.6803 6.51846C1.6803 3.57774 4.07259 1.18517 7.01362 1.18517C9.95465 1.18517 12.3469 3.57774 12.3469 6.51846C12.3469 9.45917 9.95465 11.8517 7.01362 11.8517C4.07259 11.8517 1.6803 9.45917 1.6803 6.51846Z"
                  fill="#323338"
                />
              </svg>
            </div>
             {
              users && users.map((item,index)=>{
                const isUserAssigned = usersForProjectAssignState.includes(item._id)
                // alert(isUserAssigned)
                return <div className="asiignProfile" key={index}>
                  <div
                    className="form-group AllFilter_Select pt-0"
                    action="#"
                  >
                    <input 
                    className="form-check-input" 
                    type="checkbox" 
                    id={`btn${index}`} 
                    checked={isUserAssigned}
                    onClick={()=>handleUserToggle(item._id)}
                    />
                    <label htmlFor={`btn${index}`}>
                      <div className="assignFlexpro">
                        <div>
                          <img
                            src={`${
                              (item.profilePicture)
                              ? item.profilePicture
                              : "/assets/images/user.png"
                          }`}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="assiContentInn">
                          <span>{`${item.firstName} ${item.lastName}`}</span>
                          <span className="grayText">{item.designationId.designationName}</span>
                          <span className="pinktext">{item.departmentId.departmentName}</span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              })
             } 
          </div>
          <div className="">
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    )
}
export default AssignTask