import React, { useState } from 'react';
import PropTypes from 'prop-types';
import config from "src/config";
import { putRequest } from 'src/utils/RequestUtil';
import { toast } from 'react-toastify';

const AdminView = ({ data }) => {
    const [project, setProject] = useState({});
    const handleStatus = (project) => {
        setProject(project)
    }

    const submitStatus = async (status) => {
        // console.log('logs data22', status, project);
        try {
            const url = `${config.baseUrl}project/update/${project._id}`;
            const result = await putRequest({
                url,
                data: { "ProjectApprovalStatus": status },
            });
            console.log(result);
            toast.success('Update successfully!');
        } catch (error) {
            toast.error('Failed to save.');
        } finally {
            // setIsLoading(false);
        }
        setItem(prevState => ({
            ...prevState,
            bgClass: approvalClass[status],
            ProjectApprovalStatus: status
        }));
        setProject({})
    }
    const liOption = [
        {
            class: 'bi bi-check-circle-fill text-success-500',
            path: 'M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z',
            status: 'Approved',
            bgClass: 'success',
        },
        {
            class: 'bi bi-clock-fill text-warning-700',
            path: 'M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z',
            status: 'Pending',
            bgClass: 'warning',
        },
        {
            class: 'bi bi-x-circle-fill text-danger-500',
            path: 'M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z',
            status: 'Rejected',
            bgClass: 'danger',
        }
    ]
    const approvalClass = liOption.reduce((acc, item) => {
        acc[item.status] = item.bgClass;
        return acc;
    }, {});
    const [item, setItem] = useState({
        bgClass: approvalClass[data.value.ProjectApprovalStatus],
        ProjectApprovalStatus: data.value.ProjectApprovalStatus
    });

    return (
        <td class={`p-0 bg-${item.bgClass}-500`}>
            <div className={`btn-group w-100`}>
                <div
                    className={`bg-${item.bgClass}-500 d-flex align-items-center gap-2 rounded-0 px-16 text-white  w-100`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                        fill="currentColor" className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> {item.ProjectApprovalStatus}
                </div>
                <button type="button"
                    className={`btn btn-${item.bgClass}-500 dropdown-toggle dropdown-toggle-split rounded-0 border-start px-16`}
                    data-bs-toggle="dropdown" aria-expanded="false"
                    data-bs-reference="parent"
                    onClick={() => handleStatus(data.value)}
                >
                    <span className="visually-hidden">{item.ProjectApprovalStatus}</span>
                </button>
                <ul className="dropdown-menu">
                    {
                        liOption.map((li, idx) => {
                            return (
                                <>
                                    {
                                        li.status != item.ProjectApprovalStatus ? (
                                            <li key={idx} className="" onClick={() => submitStatus(li.status)}><a
                                                className="dropdown-item d-flex align-items-center gap-2"
                                                href="javascript:void(0)" onClick={(e) => { e.preventDefault() }}><svg xmlns="http://www.w3.org/2000/svg"
                                                    width="22" height="22" fill="currentColor"
                                                    className={li.class}
                                                    viewBox="0 0 16 16">
                                                    <path d={li.path} />
                                                </svg> {li.status}</a></li>
                                        ) : ''
                                    }
                                </>
                            )
                        })
                    }
                </ul>
            </div>
        </td>
    )
}

const UserView = ({ data }) => {
    return (
        <>
            {
                data.value.ProjectApprovalStatus == 'Rejected' ? (
                    <div className="bg-danger-focus text-danger-main px-16 py-4 rounded-pill fw-medium d-flex align-items-center gap-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={22}
                            fill="currentColor"
                            className="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                        >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                        </svg>
                        <div className="accessStatus">
                            {" "}
                            Rejected
                            <span className="d-block text-neutral-500 text-sm">
                                by {data.value.RefCreatedBy}
                            </span>
                        </div>
                    </div>
                ) : data.value.ProjectApprovalStatus == 'Approved' ? (

                    <div className="bg-success-focus text-success-main px-16 py-4 rounded-pill fw-medium d-flex align-items-center gap-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={22}
                            fill="currentColor"
                            className="bi bi-check-circle-fill"
                            viewBox="0 0 16 16"
                        >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                        </svg>
                        <div className="accessStatus">
                            {" "}
                            Approved
                            <span className="d-block text-neutral-500 text-sm">
                                by {data.value.RefCreatedBy}
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="bg-warning-focus text-warning-main px-16 py-12 rounded-pill fw-medium d-flex align-items-center gap-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={22}
                            fill="currentColor"
                            className="bi bi-clock-fill"
                            viewBox="0 0 16 16"
                        >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"></path>
                        </svg>
                        <div className="accessStatus"> Pending </div>
                    </div>
                )
            }
        </>
    )
}


const DropDownSelect = (props) => {
    return (
        <>
            {props.role == 'admin' ? (
                <AdminView data={props} />
            ) : (
                <UserView data={props} />
            )}
        </>
    )
};

// PropTypes for validation
// DropDownSelect.propTypes = {
//     bgClass: PropTypes.string.isRequired,
// };

export default DropDownSelect;
