import React from 'react'; 
import FilterPopup from './FilterPopup';
import ManageColumn from './ManageColumn';
import EmployeeTabs from '../EmployeeTabs';
 

const EmployeeSkillContainer=()=>{
  return (
    <>  
  <div className="row">
    <div className="col-xxl-12 col-xl-12">
      <div className="card h-100">
        <div className="card-body p-24">
          <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
            <button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                width={30}
                height={30}
              >
                <g data-name="20-Arrow Left">
                  <path
                    fill="#0073ea"
                    d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"
                  />
                  <path
                    fill="#0073ea"
                    d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"
                  />
                </g>
              </svg>
            </button>{" "}
            AplusXpert
          </h2>
          <ul className="d-flex align-items-center gap-2">
            <li className="fw-medium">
              <a
                href="index-2.html"
                className="d-flex align-items-center gap-1 hover-text-primary"
              >
                <iconify-icon
                  icon="solar:home-smile-angle-outline"
                  className="icon text-lg"
                ></iconify-icon>
                Special Access
              </a>
            </li>
            <li>-</li>
            <li className="fw-medium">Skill</li>
          </ul>
          <EmployeeTabs/>
          <div className="d-flex gap-2 align-items-center py-16">
            {/* Search start */}
            <div className="search">
              <span className="searchBtn position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                    strokeWidth={32}
                    d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
                  ></path>
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={32}
                    d="M338.29 338.29L448 448"
                  ></path>
                </svg>
              </span>
              <input
                type="text"
                className="form-control radius-8"
                id="search"
                placeholder="Search"
              />
            </div>
            {/* Search End */}
            {/* Date Range piker start  */}
            <div className="datepker position-relative">
              <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                <iconify-icon
                  icon="solar:calendar-linear"
                  className="icon text-lg"
                />
              </span>
              <input
                className="form-control radius-8 bg-base"
                type="date"
                id="flat"
                placeholder="Date"
              />
            </div>
            {/* Date Range piker end  */}
            {/* Manage Column btn start */}
            <div className="FilterBtn">
              <button
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#manageColRightPopup"
                aria-controls="manageColRightPopup"
                className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 text-sm radius-8 px-20 py-9 d-flex align-items-center gap-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  fill="currentColor"
                  className="bi bi-columns"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm8.5 0v8H15V2zm0 9v3H15v-3zm-1-9H1v3h6.5zM1 14h6.5V6H1z" />
                </svg>{" "}
                Manage Column
              </button>
            </div>
            {/* Manage Column btn end */}
            {/* Filter btn start */}
            <div className="FilterBtn">
              <button
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#filterRightPopup"
                aria-controls="filterRightPopup"
                className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 radius-8 px-20 py-9 d-flex align-items-center gap-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="1.5em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
                  />
                </svg>{" "}
                Filter
              </button>
            </div>
            {/* Filter btn end */}
          </div>
          <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
            <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                className="bi bi-stack"
                viewBox="0 0 16 16"
              >
                <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
              </svg>{" "}
              Project Code_315B
            </h6>
          </div>
          <div
            className="table-responsive table-container"
            id="table-container"
          >
            <table className="table basic-border-table mb-0 table-hover">
              <thead>
                <tr className="dnd-moved">
                  <th className="w30px dragablefalse" draggable="false">
                    <input className="form-check-input" type="checkbox" />{" "}
                  </th>
                  <th className="w30px dragablefalse" draggable="false">
                    <label className="form-check-label">S.No.</label>
                  </th>
                  <th className="">Department </th>
                  <th className="">Cadre </th>
                  <th className="text-center">No. of Hours </th>
                  <th className="text-center">Cost </th>
                </tr>
              </thead>
              <tbody id="table-body">
                <tr className="dnd-moved">
                  <td draggable="false">
                    <input className="form-check-input" type="checkbox" />
                  </td>
                  <td draggable="false">
                    <label className="form-check-label">01</label>
                  </td>
                  <td className="position-relative">
                    <div className="text-primary-900 ps-5 fw-bold">
                      <span className="imgAbst bg-primary-900">
                        <img src="assets/images/left-sidebar/department-icon.svg" />
                      </span>
                      Content Marketing
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-people"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                      </svg>
                      <div className="flex-grow-1">
                        <span className="text-md mb-0 fw-normal text-secondary-light">
                          Animation
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#02BC7D"
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        />
                      </svg>{" "}
                      10
                    </div>
                  </td>
                  <td className="text-center">
                    <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                      ₹ 600
                    </span>
                  </td>
                </tr>
                <tr className="dnd-moved">
                  <td draggable="false">
                    <input className="form-check-input" type="checkbox" />
                  </td>
                  <td draggable="false">
                    <label className="form-check-label">02</label>
                  </td>
                  <td className="position-relative">
                    <div className="text-primary-900 ps-5 fw-bold">
                      <span className="imgAbst bg-primary-900">
                        <img src="assets/images/left-sidebar/department-icon.svg" />
                      </span>
                      Content Marketing
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-people"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                      </svg>
                      <div className="flex-grow-1">
                        <span className="text-md mb-0 fw-normal text-secondary-light">
                          Animation
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#02BC7D"
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        />
                      </svg>{" "}
                      10
                    </div>
                  </td>
                  <td className="text-center">
                    <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                      ₹ 600
                    </span>
                  </td>
                </tr>
                <tr className="dnd-moved">
                  <td draggable="false">
                    <input className="form-check-input" type="checkbox" />
                  </td>
                  <td draggable="false">
                    <label className="form-check-label">03</label>
                  </td>
                  <td className="position-relative">
                    <div className="text-primary-900 ps-5 fw-bold">
                      <span className="imgAbst bg-primary-900">
                        <img src="assets/images/left-sidebar/department-icon.svg" />
                      </span>
                      Content Marketing
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-people"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                      </svg>
                      <div className="flex-grow-1">
                        <span className="text-md mb-0 fw-normal text-secondary-light">
                          Animation
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#02BC7D"
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        />
                      </svg>{" "}
                      10
                    </div>
                  </td>
                  <td className="text-center">
                    <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                      ₹ 600
                    </span>
                  </td>
                </tr>
                <tr className="dnd-moved">
                  <td draggable="false">
                    <input className="form-check-input" type="checkbox" />
                  </td>
                  <td draggable="false">
                    <label className="form-check-label">04</label>
                  </td>
                  <td className="position-relative">
                    <div className="text-primary-900 ps-5 fw-bold">
                      <span className="imgAbst bg-primary-900">
                        <img src="assets/images/left-sidebar/department-icon.svg" />
                      </span>
                      Content Marketing
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-people"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                      </svg>
                      <div className="flex-grow-1">
                        <span className="text-md mb-0 fw-normal text-secondary-light">
                          Animation
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#02BC7D"
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        />
                      </svg>{" "}
                      10
                    </div>
                  </td>
                  <td className="text-center">
                    <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                      ₹ 600
                    </span>
                  </td>
                </tr>
                <tr className="dnd-moved">
                  <td draggable="false">
                    <input className="form-check-input" type="checkbox" />
                  </td>
                  <td draggable="false">
                    <label className="form-check-label">05</label>
                  </td>
                  <td className="position-relative">
                    <div className="text-primary-900 ps-5 fw-bold">
                      <span className="imgAbst bg-primary-900">
                        <img src="assets/images/left-sidebar/department-icon.svg" />
                      </span>
                      Content Marketing
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-people"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                      </svg>
                      <div className="flex-grow-1">
                        <span className="text-md mb-0 fw-normal text-secondary-light">
                          Animation
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#02BC7D"
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        />
                      </svg>{" "}
                      10
                    </div>
                  </td>
                  <td className="text-center">
                    <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                      ₹ 600
                    </span>
                  </td>
                </tr>
                <tr className="dnd-moved">
                  <td draggable="false">
                    <input className="form-check-input" type="checkbox" />
                  </td>
                  <td draggable="false">
                    <label className="form-check-label">06</label>
                  </td>
                  <td className="position-relative">
                    <div className="text-primary-900 ps-5 fw-bold">
                      <span className="imgAbst bg-primary-900">
                        <img src="assets/images/left-sidebar/department-icon.svg" />
                      </span>
                      Content Marketing
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-people"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                      </svg>
                      <div className="flex-grow-1">
                        <span className="text-md mb-0 fw-normal text-secondary-light">
                          Animation
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#02BC7D"
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        />
                      </svg>{" "}
                      10
                    </div>
                  </td>
                  <td className="text-center">
                    <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                      ₹ 600
                    </span>
                  </td>
                </tr>
                <tr className="dnd-moved">
                  <td draggable="false">
                    <input className="form-check-input" type="checkbox" />
                  </td>
                  <td draggable="false">
                    <label className="form-check-label">07</label>
                  </td>
                  <td className="position-relative">
                    <div className="text-primary-900 ps-5 fw-bold">
                      <span className="imgAbst bg-primary-900">
                        <img src="assets/images/left-sidebar/department-icon.svg" />
                      </span>
                      Content Marketing
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-people"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4" />
                      </svg>
                      <div className="flex-grow-1">
                        <span className="text-md mb-0 fw-normal text-secondary-light">
                          Animation
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#02BC7D"
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        />
                      </svg>{" "}
                      10
                    </div>
                  </td>
                  <td className="text-center">
                    <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                      ₹ 600
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2} />
                  <td colSpan={2} className="fw-bold">
                    Total
                  </td>
                  <td className="text-center">
                    <span className="d-block fw-bold">254</span> Total Hours
                  </td>
                  <td className="text-center">
                    <span className="d-block fw-bold">₹852</span> Total Cost
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
 <FilterPopup/>
 <ManageColumn/>
    </>
  )
}

export default EmployeeSkillContainer