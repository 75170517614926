import React from 'react';
import { Routes, Route } from "react-router-dom";
import ProjectSearchContainer from './ProjectSearchContainer';
import ModuleContainer from './Module/ModuleContainer';
import TaskContainer from './Task/TaskContainer';
import ViewContainer from './View/ViewContainer';



const ProjectContainer = () => {
  return (
    <>
      <Routes>
        <Route index element={<ProjectSearchContainer />} />
        <Route path="task/:projectId" element={<TaskContainer />} />
        <Route path="module/:projectId" element={<ModuleContainer />} />
        <Route path="view/*" element={<ViewContainer />} />
      </Routes>
    </>
  )
}

export default ProjectContainer