import { useEffect, useState, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import FilterPopup from './FilterPopup';
import ProjectListCart from './ProjectListCart';
import ProjectGridCart from './ProjectGridCart';
import { projectEditAction, projectSearchAction, projectUpsertAction } from './actions/ProjectActions';
import { debounce } from 'lodash';
import { useNavigate } from "react-router-dom";
import { productionProjectAssigneeSearchAction } from '../production-container/actions/ProductionActions';
import { getProjectForClientDeliveryFromAPI } from 'src/api/ClientProductionApi';
import SearchInput from 'src/components/SearchInput';
import DatePickerComponent from 'src/components/DatePickerComponent';

const SearchFilter = ({ where, setWhere }) => {
  const handleChange = debounce((e) => {
    setWhere({ ...where, [e.target.name]: e.target.value });
  }, 1000)

  const onDateRangeChange = (dateVal) => {
    const [startDate, endDate] = dateVal;
    if (endDate) {
      setWhere({ ...where, startDate, endDate });
    }
  }
  return (
    <>
      <div className="d-flex gap-2 align-items-center py-16">
        {/* Search start */}
        <SearchInput onChange={handleChange}/>
        <DatePickerComponent onDateRangeChange={onDateRangeChange} />
        {/* Date Range piker end  */}
        {/* Filter btn start */}
        <div className="FilterBtn">
          <button
            type="button"
            className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
            data-bs-toggle="offcanvas"
            data-bs-target="#special-access-fil"
            aria-controls="special-access-fil"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2em"
              height="1.5em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
              />
            </svg>{" "}
            Filter
          </button>
        </div>
        {/* Filter btn end */}
      </div>
    </>
  )
}

const ProjectSearchContainer = () => {
  const {
    search,
  } = useSelector((state) => state.ProjectReducer, shallowEqual);
  const {_id:userId} = useSelector(state=>state.auth?.user,shallowEqual);
  const [where, setWhere] = useState({'participants.userId':userId,'participants.status':"Active"});
  const [pageNo, setPageNo] = useState(0);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  useEffect(() => {
    const payload = {
      where,
      currentPage: pageNo,
      autopopulate: true,
      fullTextSearch: true,
    }
    dispatch(projectSearchAction(payload));
  }, [where, pageNo])
  const editClick = useCallback((type, projectId) => {
    const currentProject = search.data.find((project) => project._id === projectId); 
      dispatch(projectEditAction(currentProject));
 
    if (type === "view") {
      navigation(`/projects/view/skill/${projectId}`);
    }
    else {
      navigation(`/projects/task/${projectId}`);
    }
  }, [
    navigation
  ]);
  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 font-24">
                <button>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <g data-name="20-Arrow Left">
                      <path d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z" />
                      <path d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z" />
                    </g>
                  </svg>
                </button>{" "}
                My Projects
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="#"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
              </ul>
              <SearchFilter
                where={where}
                setWhere={setWhere}
                editClick={editClick}
              />
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-info-600">
                  Total Project: {search.count}
                </h6>
                <ul
                  className="nav border-gradient-tab nav-pills mb-0"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link d-flex align-items-center active"
                      id="pills-to-do-list-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-to-do-list"
                      type="button"
                      role="tab"
                      aria-controls="pills-to-do-list"
                      aria-selected="true"
                    >
                      <img
                        src="/assets/images/left-sidebar/grid-view.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link d-flex align-items-center"
                      id="pills-recent-leads-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-recent-leads"
                      type="button"
                      role="tab"
                      aria-controls="pills-recent-leads"
                      aria-selected="false"
                      tabIndex={-1}
                    >
                      <img
                        src="assets/images/left-sidebar/view-list.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-to-do-list"
                  role="tabpanel"
                  aria-labelledby="pills-to-do-list-tab"
                  tabIndex={0}
                >
                  <div className="row gy-4">
                    {
                      search.data.map((project, index) => {                        
                        const {currentMonthDelivery,overAllDelivery} = search.delivery.filter(project=>(project._id===project._id))[0]||{currentMonthDelivery:0,overAllDelivery:0};
                        return (
                          <ProjectGridCart
                            project={project}
                            editClick={editClick}
                            currentMonthDelivery={currentMonthDelivery}
                            overAllDelivery={overAllDelivery}
                            key={index} />
                        )
                      })
                    }
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-recent-leads"
                  role="tabpanel"
                  aria-labelledby="pills-recent-leads-tab"
                  tabIndex={0}
                >
                  <div className="row gy-4">
                    {
                      search.data.map((project, index) => {
                        const {currentMonthDelivery,overAllDelivery} = search.delivery.filter(project=>(project._id===project._id))[0]||{currentMonthDelivery:0,overAllDelivery:0};
                        return (
                          <ProjectListCart 
                            project={project} 
                            editClick={editClick}
                            currentMonthDelivery={currentMonthDelivery}
                            overAllDelivery={overAllDelivery}
                            key={index} 
                           />
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FilterPopup where={where} setWhere={setWhere} />
    </>
  )
}
export default ProjectSearchContainer