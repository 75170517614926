import { useEffect } from 'react';
import config from "src/config";
import { useNavigate } from 'react-router-dom';
import { getRequest } from 'src/utils/RequestUtil';
import { useNotification } from 'src/components/Notifination/ToastNotification';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../home-container/actions/authAction';

const AuthCheck = () => {
    const { openSuccessNotification, openErrorNotification } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const checkAuthRefValidation = async (authId) => {
        try {
            const response = await getRequest({
                url: `${config.baseUrl}auth/checkAuthRef/${authId}`
            });
            return response.data;
        } catch (error) {
            console.error('Error validating auth ref:', error);
            return null;
        }
    };

    const handleCheckAuth = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const checkAuthRef = urlParams.get('checkAuthRef');
        const calBackURL = urlParams.get('calBackURL');

        if (checkAuthRef) {
            const { token, user } = await checkAuthRefValidation(checkAuthRef);
            if (token) { 
                    localStorage.setItem("user", JSON.stringify({ ...user }));
                    localStorage.setItem("token", JSON.stringify(token)); 
                    // localStorage.setItem("userData", JSON.stringify(values)); 
                    dispatch(loginSuccess({ ...user, jwtToken: token })); 
                    openSuccessNotification("Welcome! Logged In Successfully.");
 
                if (calBackURL) {
                    navigate(`/${calBackURL}`, { replace: true });
                } else {
                    navigate('/', { replace: true });
                }
            } else {
                navigate('/login', { replace: true });
            }
        } else {
            navigate('/login', { replace: true });
        }
    };
    handleCheckAuth();

    return (
        <div>
            Checking authentication...
        </div>
    );
};

export default AuthCheck;
