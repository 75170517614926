import { shallowEqual, useSelector } from "react-redux"
import { formatDate, formatTime } from "src/utils/utils";
import { useDispatch } from 'react-redux';

const ClientProductionList=()=>{
    // const {} = props;
    const dispatch = useDispatch();
 
   const {search} = useSelector((state) => state.ClientProductionReducer,shallowEqual);
   const handleComment = (production) => {
        dispatch({ type: "CLIENT_PRODUCTION_CURRENT_CLIENT_PRODUCTION", data: production });
     };
     const handleFileDownload = (production) => {
        production?.files.forEach(file => {
          const a = document.createElement('a');
          a.href = file.url;
          a.target = '_blank'; // Open in a new tab
          a.click();
        });
      };
 return (
    <>
    {
        search?.data?.map((item,index)=>{  
            return(
                <tr className="dnd-moved" key={item._id}> 
                <td draggable="false">
                  <label className="form-check-label">{index+1}</label>
                </td>
                <td className="position-relative"> {formatDate(item?.ts)}</td>
                <td> {formatDate(item?.date)}</td>
                <td>
                  <div className="d-flex align-items-center gap-2">
                    <span className="userimg" />
                    <div className="flex-grow-1">
                      <h6 className="text-md mb-0 fw-medium text-primary-600">
                        {item.userId?.firstName + " " + item.userId?.lastName}
                      </h6>
                    </div>
                  </div>
                </td>
                <td>{item.userId?.departmentId?.departmentName} </td>
                <td className="text-center">{item.userId?.designationId?.designationName}</td>
                <td className="text-center">
                  <div className="d-flex flex-wrap align-items-center justify-content-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="#02BC7D"
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                      />
                    </svg>{" "}
                    {item?.totalDurations}
                  </div>
                </td>
                <td className="text-center">
                  <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                    ₹{item?.cost||0}
                  </span>
                </td>
                <td className="text-center">
                        {
                            (item?.files?.length>0)?
                            <button className="d-flex align-items-center gap-2 text-primary-600"
                            onClick={()=>{handleFileDownload(item)}}
                            >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={22}
                                height={22}
                                fill="currentColor"
                                className="bi bi-file-earmark-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z" />
                            </svg>{" "}
                            View
                            </button>
                            :"NA"
                        }
                            
                        </td>
                        <td className="text-center">
                                <button
                                className="text-primary-900"
                                data-bs-toggle="modal"
                                data-bs-target="#ClientCommentModal"
                                onClick={() => handleComment(item)}
                                >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={22}
                                    height={22}
                                    fill="currentColor"
                                    className="bi bi-eye-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                </svg>
                                </button>
                        </td>
              </tr>
                )
            })
        } 
    </>
 )
}
export default ClientProductionList