import { useState } from "react"

const FilterPopup=({where,setWhere})=>{
  const [status,setStatus] =useState(null);
  const [OAD,setOAD] = useState({type:'above',value:null});
  const [CMD,setCMD] = useState({type:'Below',value:null});

  const applyFilter = () => {
    let updatedWhere = { ...where };
    // Check if status is set
    if (status) {
        updatedWhere.status = status;
    }
    // Check if OAD has a value
    if (OAD?.value) {
        updatedWhere.OAD = OAD;
    }
    // Check if CMD has a value
    if (CMD?.value) {
        updatedWhere.CMD = CMD;
    }
    // Update the `where` state with the combined filters
    setWhere(updatedWhere);
};
    return(
        <div
  className="offcanvas offcanvas-end filterSecPopup"
  id="special-access-fil"
  aria-labelledby="filterRightLayout"
>
  <div className="offcanvas-header">
    <h5 className="offcanvas-title" id="filterRightLayout">
      Filter
    </h5>
    <button
      type="button"
      className="btn-close d-none"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    />
  </div>
  <div className="offcanvas-body pt-0">
    <div className="assignPopoHeight">
      {/* <div className="selectSec filterStyleSec">
        <label htmlFor="">Project</label>
        <select id="departmentSec">
          <option value="">Select Project Code</option>
          <option value={1}>Awesome</option>
          <option value={2}>Beast</option>
          <option value={3}>Compatible</option>
          <option value={4}>Thomas Edison</option>
          <option value={5}>Nikola</option>
        </select>
      </div> */}
      <form className="AllFilter_Select" action="">
        {/* <input className="form-check-input" type="checkbox" id="allFilter" />
        <label className="allFilter" htmlFor="allFilter">
          All Filter <span>3 Select</span>
        </label> */}
      </form>
      <div className="selectSec pt-2 radioBtnActiveDe">
        <label htmlFor="">Status</label>
        <div className="form-group AllFilter_Select pt-0" action="">
          <input
            className="form-check-input radio"
            type="checkbox"
            defaultValue={1}
            name="fooby[1][]"
            id="stactive"
            onClick={()=>{setStatus(true)}}
          />
          <label htmlFor="stactive">Active</label>
        </div>
        <div className="form-group AllFilter_Select pt-0" action="">
          <input
            className="form-check-input radio"
            type="checkbox"
            defaultValue={1}
            name="fooby[1][]"
            id="stdeactive"
            defaultChecked=""
            onClick={()=>{setStatus(false)}}
          />
          <label htmlFor="stdeactive">Deactive</label>
        </div>
      </div>
      <div className="selectSec pt-0">
        <label htmlFor="">OAD</label>
        <div className="costSec">
          <div className="costinputSec">
            <label htmlFor="">₹</label>
            <input type="number" name="OAD" min={0} defaultValue="00" onChange={(e)=>setOAD({...OAD,value:e.target.value})}/>
          </div>
          <select className="slectIconsShow" onChange={(e)=>setOAD({...OAD,type:e.target.value})}>
            <option value="Above">Above</option>           
            <option value="Below">Below</option>           
          </select> 
        </div>
      </div>
      <div className="selectSec pt-0">
        <label htmlFor="">CMD</label>
        <div className="costSec">
          <div className="costinputSec">
            <label htmlFor="">₹</label>
            <input type="number" name="CMD" min={0} defaultValue="00" onChange={(e)=>setCMD({...CMD,value:e.target.value})}/>
          </div>
          <select className="slectIconsShow" onChange={(e)=>setCMD({...CMD,type:e.target.value})}>
            <option value="Above">Below</option>
            <option value="Below">Above</option>
          </select>
        </div>
      </div>
    </div>
    <div className="">
      <button
        type="button"
        className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
        onClick={applyFilter}
      >
        Apply Filter
      </button>
    </div>
  </div>
</div>

    )
}
export default FilterPopup