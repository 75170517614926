import { useCallback, useState } from "react"
import { useDispatch } from 'react-redux';
import { getTaskByProjectFromAPI } from "src/api/UserApi"
import { productionAssignProjectIdAction } from "../actions/ProductionActions";
import TaskTree from "./TaskTree";

const SubProjectTree=({project})=>{
    const [isOpen,setIsOpen] = useState(false)
    const [tasks,setTasks] = useState([])
    const dispatch = useDispatch();
    // const handleLoadModuleByProject=(project)=>{
    //     setIsOpen(!isOpen)
    //     console.log('load module by project',project)
    //     alert("load module by project")
    // }
    const handleLoadModuleByProject = useCallback(async(e,projectId)=>{
        // userId
        e.stopPropagation(); // Prevent event propagation
        setIsOpen(!isOpen)
        const task = await getTaskByProjectFromAPI(projectId)
        setTasks(task.data.data)
        dispatch(productionAssignProjectIdAction({
          data:projectId
        }));
      },[tasks])
    return (
      <>
       <li 
       className={`has-children ${isOpen ? 'is-open' : ''}`}
       onClick={(e) => {handleLoadModuleByProject(e,project._id)}}
       >
                    <div className="acnav__label acnav__label--level4">{project.ProjectSubCode}</div>
                    {/* start level 4 */}
                    <ul className="acnav__list acnav__list--level4">
                      <li>
                        <a
                          className="proHeadColoPink acnav__link acnav__link--level5"
                          href="javascript:void(0)"
                        >
                          Select Task
                        </a>
                      </li>
                      {
                        tasks.map((task,taskIndex)=>{
                          return(
                            <TaskTree task={task} key={taskIndex}/>
                          )
                        })
                      }
                                      
                    </ul>
                    {/* end level 4 */}
                  </li>
      </>
    )
  }
  export default SubProjectTree