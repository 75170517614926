import { time_ago } from "src/utils/CommonUtils";
const ProjectListCart=({
  project,
  editClick,
  currentMonthDelivery,
  overAllDelivery,
})=>{
    return (
        <>
        <div className="col-xxl-12 col-sm-12 listSteps">
                <div className="card radius-12 h-100 border shadow-none overflow-hidden prjwhc gbtmb-10">
                  <div className="card-body py-16 px-24">
                    <div className="card-header py-0 px-0 bg-base d-flex align-items-center gap-1 justify-content-between border-0">
                      <h6 className="text-sm mb-0">Project Code: {project.ProjectSubCode}</h6>
                      <span className={`${(project.ProjectStatus==="Active")?'bg-success-focus text-success-main':'bg-danger-focus text-danger-main'} px-24 py-4 radius-4 fw-medium text-sm`}>
                        {(project?.ProjectStatus==="Active")?"Active":"Inactive"}
                      </span>
                    </div>
                    <div className="wst">{project?.RefClientId?.ClientProjectCode}</div>
                    <ul>
                    {project.participants && (
                                  <>
                                  {project.participants.slice(0, 5).map((participant, userIndex) => (
                                      <li key={`user_${userIndex}`}>
                                        <span className="Projtnm">{participant?.userId?.designationId?.designationName}</span>{" "}
                                        <span className="dots">:</span> {`${participant?.userId?.firstName} ${participant?.userId?.lastName}`}
                                      </li>
                                  ))}
                                  </>
                                )}
                      {/* <li>
                        <span className="Projtnm">Project Director</span>{" "}
                        <span className="dots">:</span> Darlene Robertson
                      </li>
                      <li>
                        <span className="Projtnm">Project Manager</span>{" "}
                        <span className="dots">:</span> Raghavendra Srivastava
                      </li>
                      <li>
                        <span className="Projtnm">Project Coordinator</span>{" "}
                        <span className="dots">:</span> Esther Howard
                      </li>
                      <li>
                        <span className="Projtnm">Created Date</span>{" "}
                        <span className="dots">:</span> 05 July 2024, 04:15am
                      </li> */}
                      <li>
                        <span className="Projtnm">Created Date</span>{" "}
                        <span className="dots">:</span> {time_ago(project.ts)}
                      </li> 
                    </ul>
                    <div className="d-flex align-items-center flex-wrap mt-16">
                    {project.participants && (
                    <>
                      {project.participants.slice(0, 5).map((participant, userIndex) => (
                          <img
                          src={`${
                            (participant?.userId?.profilePicture)
                            ? participant?.userId?.profilePicture
                            : "/assets/images/user.png"
                        }`} 
                           key={`user_${userIndex}`}
                          alt=""
                          className="w-32-px h-32-px rounded-circle object-fit-cover position-relative"
                        />
                      ))}
                        </>
                      )}
                      {project.participant?.length > 5 && (
                      <span className="w-32-px h-32-px rounded-circle object-fit-cover position-relative ms--10px border bg-neutral-100 text-secondary-light text-xs d-inline-flex align-items-center justify-content-center">
                          +{project.participants?.length - 5}
                      </span>
                      )}
                      {/* <img
                        src="assets/images/avatar/avatar-group1.png"
                        alt=""
                        className="w-32-px h-32-px rounded-circle object-fit-cover position-relative"
                      />
                      <img
                        src="assets/images/avatar/avatar-group2.png"
                        alt=""
                        className="w-32-px h-32-px rounded-circle object-fit-cover position-relative ms--10px"
                      />
                      <img
                        src="assets/images/avatar/avatar-group3.png"
                        alt=""
                        className="w-32-px h-32-px rounded-circle object-fit-cover position-relative ms--10px"
                      />
                      <img
                        src="assets/images/avatar/avatar-group4.png"
                        alt=""
                        className="w-32-px h-32-px rounded-circle object-fit-cover position-relative ms--10px"
                      />
                      <span className="w-32-px h-32-px rounded-circle object-fit-cover position-relative ms--10px border bg-neutral-100 text-secondary-light text-xs d-inline-flex align-items-center justify-content-center">
                        +5
                      </span> */}
                    </div>
                  </div>
                  <div className="mt-12 d-flex align-items-center justify-content-between gap-10 border-top px-16 py-16">
                    <div className="d-flex align-items-center justify-content-between gap-10">
                      <div className="fstx">
                        <span>OAD</span> ₹{overAllDelivery}
                      </div>
                      <div className="lstx">
                        <span>CMD</span> ₹{currentMonthDelivery}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between gap-10">
                      <button
                        type="button"
                        className="card-edit-button text-success-600"
                        id={project._id}
                        onClick={()=>{editClick('edit',project._id)}}
                      >
                        <iconify-icon
                          icon="lucide:edit"
                          className="icon text-lg line-height-1"
                        />
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary-600 radius-6 px-14 py-6 text-sm"
                        onClick={()=>{editClick('view',project._id)}}
                      >
                        View Report
                      </button>
                    </div>
                  </div>
                </div>
              </div>
        </>
    )
}
export default ProjectListCart