import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { PROJECT_ACTIONS } from '../actions/ProjectActions';
import { ProjectDefaultProps } from '../ProjectPropTypes';

const initialState = () => ({
    currentRecord: { ...ProjectDefaultProps },

    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case PROJECT_ACTIONS.PROJECT_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case PROJECT_ACTIONS.PROJECT_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case PROJECT_ACTIONS.PROJECT_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case PROJECT_ACTIONS.PROJECT_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case PROJECT_ACTIONS.PROJECT_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case PROJECT_ACTIONS.PROJECT_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case PROJECT_ACTIONS.PROJECT_RESET: {
            return {
                ...state,
                currentRecord: { ...ProjectDefaultProps },
            };
        }

        default:
            return state;
    }
};
