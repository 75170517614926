import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const projectSearchAPI = function (data) {
    const url = `${config.baseUrl}project/search`;
    return postRequest({
        url,
        data,
    });
};
export const getprojectAPI = function (data) {
    const url = `${config.baseUrl}project/getproject`;
    return postRequest({
        url,
        data,
    });
};

export const projectCreateAPI = function (data) {
    const url = `${config.baseUrl}project/save`;
    return postRequest({
        url,
        data,
    });
};

export const projectUpdateAPI = function ({
    projectId,
    data,
}) {
    const url = `${config.baseUrl}project/update/${projectId}`;
    return putRequest({
        url,
        data,
    });
};

export const projectCostAPI = function (data) {
    const url = `${config.baseUrl}project/viewProjectCost`;
    return postRequest({
        url,
        data,
    });
};


