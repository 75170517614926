import React, { useState, useEffect } from 'react';
import TopTabs from "../TopTabs"
import ProjectProductionFilter from "./FIlter"
import ManageProductionColum from "./ManageColoum"
import { useParams } from 'react-router-dom';
import { productionApproveUpdate, productionProjectSearchAPI, productionRejectionUpdate } from 'src/api/ReportsAPI';
import moment from 'moment'
import BackProjectButton from 'src/components/BackProjectButton';

const ProjectProductionContainer = () => {

  const { projectId } = useParams();
  const [production, setProduction] = useState([])
  const [totalcost, setTotalCost] = useState()
  const [totalDuration, setTotalDuration] = useState()
  const [search, setSearch] = useState('');
  const [dropdownOpenIndex, setDropdownOpenIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);
  const [selectedStatuses, setSelectedStatuses] = useState('');
  const [approvedCount, setApprovedCount] = useState('');
  const [statusid, setListId] = useState('')
  const [totalrejected, setTotalRejected] = useState()
  const [totalapproved, setTotalApproved] = useState()



  const getProjectProductionData = async () => {
    let body = {
      pageLength: 100
    };

    try {
      const resp = await productionProjectSearchAPI({ projectId, body });
      console.log('response from project Task', resp?.data?.data);
      // setProduction(resp?.data?.data);

      const skillsData = resp?.data?.data || [];

      // Filter skills based on departmentName
      const filterSkillsData = skillsData.filter(item =>
        item?.userId?.firstName.toLowerCase().includes(search.toLowerCase())
      );

      // Calculate total duration and total cost for filtered data
      const totals = filterSkillsData.reduce((acc, item) => {
        acc.totalDuration += item.totalDurations || 0;
        acc.totalApproved += item?.approvedDurations || 0;
        acc.totalRejected += item?.rejectedDurations || 0;
        acc.totalCost += item.cost || 0;
        return acc;
      }, { totalDuration: 0, totalCost: 0, totalApproved: 0, totalRejected: 0 });

      console.log('Total Duration:', totals.totalDuration);
      console.log('Total Cost:', totals.totalCost);

      // Set the filtered skills and totals in state
      setProduction(filterSkillsData);
      setTotalDuration(totals.totalDuration);
      setTotalCost(totals.totalCost);
      setTotalApproved(totals.totalApproved)
      setTotalRejected(totals.totalRejected)

    } catch (error) {
      console.log('error from project Task', error);
    }
  };

  useEffect(() => {
    getProjectProductionData();
  }, [search]);

  const handleStatusChange = (index, newStatus, id) => {
    console.log('iddd of statussssss list', id);

    const updatedProduction = [...production];
    updatedProduction[index].status = newStatus;
    setProduction(updatedProduction);

    setDropdownOpenIndex(null); // Close dropdown after selection
    setCurrentItemIndex(index);
    setSelectedStatuses(newStatus); // Save selected status in the state
    setIsModalOpen(true); // Open modal
    setListId(id)
  };

  const handleSaveApproval = async () => {
    let productionId = statusid
    let data = {
      approvedDurations: approvedCount
    }
    try {
      const resp = await productionApproveUpdate({ productionId, data })
      console.log('response from approved count', resp?.data?.data);
      getProjectProductionData()
      setListId('')
      setIsModalOpen(false); // Close modal
    } catch (error) {
      console.log('error from approved count', error);
    }
  };

  const handleSaveRejection = async () => {
    let productionId = statusid
    let data = {
      rejectedDurations: approvedCount
    }
    try {
      const resp = await productionRejectionUpdate({ productionId, data })
      console.log('response from rejection count', resp?.data?.data);
      getProjectProductionData()
      setListId('')
      setIsModalOpen(false); // Close modal
    } catch (error) {
      console.log('error from rejection count', error);
    }
  };

  const toggleDropdown = (index) => {
    setDropdownOpenIndex(dropdownOpenIndex === index ? null : index);
  };


  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
              <BackProjectButton/>
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="index-2.html"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Production </li>
              </ul>
              <TopTabs />
              <div className="d-flex gap-2 align-items-center py-16">
                {/* Search start */}
                <div className="search">
                  <span className="searchBtn position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
                      ></path>
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M338.29 338.29L448 448"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control radius-8"
                    id="search"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}
                  />
                </div>
                {/* Search End */}
                {/* Date Range piker start  */}
                {/* <div className="datepker position-relative">
                  <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <iconify-icon
                      icon="solar:calendar-linear"
                      className="icon text-lg"
                    />
                  </span>
                  <input
                    className="form-control radius-8 bg-base"
                    type="date"
                    id="flat"
                    placeholder="Date"
                  />
                </div> */}
                {/* Date Range piker end  */}
                {/* Filter btn start */}
                {/* <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#filterRightPopup"
                    aria-controls="filterRightPopup"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 radius-8 px-20 py-9 text-sm d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em" 
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
                      />
                    </svg>{" "}
                    Filter
                  </button>
                </div> */}
                {/* Filter btn end */}
                {/* Manage Column btn start */}
                {/* <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#manageColRightPopup"
                    aria-controls="manageColRightPopup"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 text-sm radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      fill="currentColor"
                      className="bi bi-columns"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm8.5 0v8H15V2zm0 9v3H15v-3zm-1-9H1v3h6.5zM1 14h6.5V6H1z" />
                    </svg>{" "}
                    Manage Column
                  </button>
                </div> */}
                {/* Manage Column btn end */}
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-stack"
                    viewBox="0 0 16 16"
                  >
                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
                  </svg>{" "}
                  Total Project: 458
                </h6>
              </div>
              <div className="table-responsive table-container" id="table-container">
                <table className="table basic-border-table mb-0 table-hover">
                  <thead>
                    <tr className="dnd-moved">
                      <th className="w30px dragablefalse" draggable="false">
                        <input className="form-check-input" type="checkbox" />{" "}
                      </th>
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th>Date</th>
                      <th>Name </th>
                      <th>Department </th>
                      <th>Designation </th>
                      <th className="text-center">Total Min </th>
                      <th>Approved Min </th>
                      <th>Pending Min </th>
                      <th>Rejected Min </th>
                      <th className="text-center">Cost </th>
                      <th className="text-center">Status </th>
                    </tr>
                  </thead>
                  <tbody id="table-body">
                    {production?.map((item, index) => {
                      console.log('rejectedd condition', item?.totalDurations === item?.rejectedDurations);

                      // Determine the status based on the conditions
                      let status;
                      if (item?.totalDurations === item?.rejectedDurations) {
                        status = "Rejected";
                      } else if (item?.totalDurations === item?.approvedDurations || (item?.totalDurations - (item?.approvedDurations + item?.rejectedDurations) === 0)) {
                        status = "Approved";
                      } else {
                        status = "Pending";
                      }


                      const approvedDurations = item?.approvedDurations || 0;
                      const rejectedDurations = item?.rejectedDurations || 0;
                      const totalDurations = item?.totalDurations || 0;

                      // Calculate Pending based on the provided conditions
                      let pending;
                      if (approvedDurations === 0 && rejectedDurations === 0) {
                        pending = totalDurations;
                      } else if (approvedDurations > 0 && rejectedDurations > 0) {
                        pending = totalDurations - (approvedDurations + rejectedDurations);
                      } else if (approvedDurations > 0) {
                        pending = totalDurations - approvedDurations;
                      } else if (rejectedDurations > 0) {
                        pending = totalDurations - rejectedDurations;
                      }

                      // Ensure pending is not negative
                      pending = Math.max(pending, 0);


                      // Ensure pending is not negative
                      pending = Math.max(pending, 0);

                      return (
                        <tr key={index} className="dnd-moved">
                          <td draggable="false">
                            <input className="form-check-input" type="checkbox" />
                          </td>
                          <td draggable="false">
                            <label className="form-check-label">{index + 1}</label>
                          </td>
                          <td>{moment(item?.date).format('DD-MM-YYYY')}</td>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <span className="userimg" />
                              <div className="flex-grow-1">
                                <h6 className="text-md mb-0 fw-medium text-primary-600">
                                  {item?.userId?.firstName} {item?.userId?.lastName}
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>{item?.userId?.departmentId?.departmentName}</td>
                          <td>{item?.userId?.designationId?.designationName}</td>
                          <td className="text-center">
                            {item?.totalDurations}
                          </td>
                          <td className="text-center">

                            {item?.approvedDurations || 0}
                          </td>
                          <td className="text-center">
                            {pending}
                          </td>
                          <td className="text-center">

                            {item?.rejectedDurations || 0}
                          </td>
                          <td className="text-center">
                            <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm">
                              ₹ {item?.cost || 0}
                            </span>
                          </td>
                          <td className="text-center">
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                              <button
                                onClick={() => toggleDropdown(index)}
                                style={{
                                  backgroundColor: status === "Approved" ? "#dff0d8" : status === "Rejected" ? "#f8d7da" : "#fff3cd",
                                  color: status === "Approved" ? "#28a745" : status === "Rejected" ? "#dc3545" : "#856404",
                                  border: `1px solid ${status === "Approved" ? "#28a745" : status === "Rejected" ? "#dc3545" : "#856404"}`,
                                  padding: '5px 10px',
                                  borderRadius: '20px',
                                  cursor: 'pointer',
                                  width: '100px',
                                  textAlign: 'center',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}
                              >
                                {status}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                  style={{ marginLeft: '8px' }}
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M1.5 5.5l6 6 6-6H1.5z"
                                  />
                                </svg>
                              </button>
                              {dropdownOpenIndex === index && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '110%',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    backgroundColor: '#ffffff',
                                    boxShadow: '0px 8px 16px rgba(0,0,0,0.2)',
                                    zIndex: 20,
                                    borderRadius: '8px',
                                    width: '120px',
                                    textAlign: 'center',
                                    border: '1px solid #ddd'
                                  }}
                                >

                                  <button
                                    onClick={() => handleStatusChange(index, 'Approved', item?._id)}
                                    style={{
                                      backgroundColor: '#dff0d8',
                                      color: '#28a745',
                                      border: 'none',
                                      padding: '8px 12px',
                                      width: '100%',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Approved
                                  </button>
                                  <button
                                    onClick={() => handleStatusChange(index, 'Rejected', item?._id)}
                                    style={{
                                      backgroundColor: '#f8d7da',
                                      color: '#dc3545',
                                      border: 'none',
                                      padding: '8px 12px',
                                      width: '100%',
                                      cursor: 'pointer',
                                      borderRadius: '0 0 8px 8px'
                                    }}
                                  >
                                    Rejected
                                  </button>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>


                  <tfoot>
                    <tr style={{ position: 'relative', zIndex: 20 }}>
                      <td colSpan={1} />
                      <td colSpan={5} className="fw-bold">
                        Total
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">{totalDuration}</span> Total Min
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">{totalapproved}</span> Total Approved
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">{totalDuration - (totalapproved + totalrejected)}</span> Total Pending
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">{totalrejected}</span> Total Rejected
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">₹{totalcost}</span> Total Amount
                      </td>
                      <td colSpan={1} />
                    </tr>


                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              width: '400px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              textAlign: 'center',
              position: 'relative', // Add this to position the close button
            }}
          >
            <button
              onClick={() => setIsModalOpen(false)}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                fontSize: '18px',
                cursor: 'pointer',
              }}
            >
              &times;
            </button>
            <h4>{selectedStatuses} Count (Min)</h4>
            <input
              type="number"
              value={approvedCount}
              onChange={(e) => setApprovedCount(e.target.value)}
              placeholder="Enter Count"
              style={{
                width: '100%',
                padding: '8px',
                margin: '10px 0',
                borderRadius: '4px',
                border: '1px solid #ddd',
              }}
            />
            <button
              onClick={selectedStatuses === 'Approved' ? handleSaveApproval : handleSaveRejection}
              style={{
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
      <ProjectProductionFilter />
      <ManageProductionColum />
    </>
  )
}
export default ProjectProductionContainer