import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LeftNavBar from '../layout-container/LeftNavBar';
import HeaderNav from '../layout-container/HeaderNav';
import ProductionContainer from '../production-container/ProductionContainer';
import Login from '../login-container/Login';
import ClientContainer from '../client-container/ClientContainer';
import ProjectContainer from '../project-container/ProjectContainer';
import UserContainer from '../user-container/UserContainer';
import EmployeeDataContainer from '../employee-container/Data/EmployeeDataContainer';
import EmployeeProductionContainer from '../employee-container/Production/EmployeeProductionContainer';
import EmployeeProjectContainer from '../employee-container/Project/EmployeeProjectContainer';
import EmployeeSkillContainer from '../employee-container/Skill/EmployeeSkillContainer';
import ModuleContainer from '../project-container/Module/ModuleContainer';

// import Home from '../home-container/Home';
function MainContainer() {
  return (
    <>
      <LeftNavBar />
      <main className="dashboard-main">
        <HeaderNav />
        <div className="dashboard-main-body">
          <Routes>

            <Route index element={<ProductionContainer />} />
            <Route path="productions" element={<ProductionContainer />} />
            <Route path="projects/*" element={<ProjectContainer />} />
            <Route path="clients" element={<ClientContainer />} />
            <Route path="employee-data" element={<EmployeeDataContainer />} />
            <Route path="view-production" element={<EmployeeProductionContainer />} />
            <Route path="view-project" element={<EmployeeProjectContainer />} />
            <Route path="skill" element={<EmployeeSkillContainer />} />
            <Route path="module" element={<ModuleContainer />} />

          </Routes>
        </div>
      </main>

      {/**/}
    </>
  );
}

export default MainContainer;
