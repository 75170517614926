import React, { useEffect } from "react";
import flatpickr from "flatpickr";
import "flatpickr/dist/plugins/monthSelect/style.css";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

const DatePickerComponent = () => {
  useEffect(() => {
    const firstDate = flatpickr("#test1", {
      plugins: [
        monthSelectPlugin({
          shorthand: false, // Use full month names
          dateFormat: "F Y", // Display full month name and year
          altFormat: "F Y", // Display full month name and year in the input
          theme: "light" // Default theme
        })
      ],
      defaultDate: new Date(), // Set the default date to the current month
    });

    document.querySelector('.leftBtn').addEventListener('click', () => {
      const currentDate = firstDate.selectedDates[0] || new Date();
      const newDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
      firstDate.setDate(newDate, true);
    });

    document.querySelector('.rightBtn').addEventListener('click', () => {
      const currentDate = firstDate.selectedDates[0] || new Date();
      const newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
      firstDate.setDate(newDate, true);
    });

    document.querySelector('.calendar-icon').addEventListener('click', () => {
      firstDate.open();
    });
  }, []);

  return (
    <div className="monthpeker d-flex align-items-center gap-2 justify-content-end position-relative">
      <button
        className="leftBtn page-link bg-primary-50 text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-44-px w-48-px">
        <iconify-icon icon="iconamoon:arrow-left-2-light" className="text-xxl"></iconify-icon>
      </button>
      <div className="position-relative">
        <span className="calendar-icon position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
          <iconify-icon icon="solar:calendar-linear" className="icon text-lg"></iconify-icon>
        </span>
        <input type="text" placeholder="Select Date.." className="form-control radius-8 h-44-px" id="test1" />
      </div>
      <button
        className="rightBtn page-link bg-primary-50 text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-44-px w-48-px">
        <iconify-icon icon="iconamoon:arrow-right-2-light" className="text-xxl"></iconify-icon>
      </button>
    </div>
  );
};

export default DatePickerComponent;
