import React, { useState, useEffect } from 'react';
import { moduleUpdateAPI } from 'src/api/ModuleApi';
import { taskSearchAPI } from 'src/api/TaskApi';

const EditModule = ({ moduleId, onTaskSaved, projectId }) => {

    console.log('moduleId dataaaa', moduleId);

    const [tasks, setTasks] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState(moduleId?.taskIds || []);
    const [moduleName, setModuleName] = useState(moduleId.moduleName || '');
    const [userId, setUserId] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);


    useEffect(() => {
        if (moduleId) {
            setModuleName(moduleId.moduleName || '');
            setSelectedTasks(moduleId.taskIds || []);
        }
    }, [moduleId]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (storedUser) {
            setUserId(storedUser?._id);
        }
    }, []);

    const searchTask = async () => {
        let body = {
            pageLength: 100,
            where: {
                projectId: projectId
            }
        };
        try {
            const resp = await taskSearchAPI(body);
            setTasks(resp?.data?.data?.data || []);
        } catch (error) {
            console.log('Error fetching task data', error);
        }
    };

    useEffect(() => {
        searchTask();
    }, []);

    const UpdateModule = async () => {
        let data = {
            moduleName: moduleName,
            taskIds: selectedTasks.map(task => task._id),
            by: userId,
        };
        try {
            const resp = await moduleUpdateAPI({ moduleId: moduleId?._id, data: data });
            if (onTaskSaved) {
                onTaskSaved();
            }
            setModuleName('');
            setSelectedTasks([]);
        } catch (error) {
            console.log('Error updating module', error);
        }
    };

    const handleTaskChange = (task) => {
        if (!selectedTasks.some(t => t._id === task._id)) {
            setSelectedTasks([...selectedTasks, task]);
            setDropdownOpen(false); // Close dropdown after selecting an option
        }
    };

    const removeTask = (taskId) => {
        setSelectedTasks(selectedTasks.filter(task => task._id !== taskId));
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };


    return (
        <div
            className="offcanvas offcanvas-end filterSecPopup"
            id="EditModule"
            aria-labelledby="addModuleSec"
        >
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="addModuleSec">
                    Edit Module
                </h5>
                <button
                    type="button"
                    className="btn-close d-none"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                />
            </div>
            <div className="offcanvas-body pt-0">
                <div className="assignPopoHeight">
                    <div className="selectSec pt-0">
                        <label htmlFor="">Module</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Module Name"
                            value={moduleName}
                            onChange={(e) => setModuleName(e.target.value)}
                        />
                    </div>
                    <div className="selectSec pt-2">
                        <label htmlFor="">Task</label>
                        <div style={styles.customDropdown}>
                            <div style={styles.selectedTasks} onClick={toggleDropdown}>
                                {selectedTasks.map(task => (
                                    <span key={task._id} style={styles.selectedTask}>
                                        {task.taskName}
                                        <button
                                            type="button"
                                            style={styles.removeTask}
                                            onClick={() => removeTask(task._id)}
                                        >
                                            &times;
                                        </button>
                                    </span>
                                ))}
                                <span style={styles.placeholder}>
                                    {selectedTasks.length === 0 && 'Select Task'}
                                </span>
                            </div>
                            {dropdownOpen && (
                                <div style={styles.dropdownOptions}>
                                    {tasks.map((task) => (
                                        <div
                                            key={task._id}
                                            style={styles.dropdownOption}
                                            onClick={() => handleTaskChange(task)}
                                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = styles.dropdownOptionHover.backgroundColor)}
                                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                                        >
                                            {task.taskName}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="FilterBtn addMorePopup"></div>
                </div>
                <div className="">
                    <button
                        type="button"
                        className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
                        onClick={UpdateModule}
                    >
                        Update
                    </button>
                </div>
            </div>
        </div>
    );
};

// Inline styles
const styles = {
    customDropdown: {
        position: 'relative',
        border: '1px solid #ced4da',
        borderRadius: '4px',
        padding: '4px 12px',
        cursor: 'pointer',
    },
    selectedTasks: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        minHeight: '38px',
    },
    selectedTask: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f1f1f1',
        borderRadius: '4px',
        padding: '4px 8px',
        marginRight: '4px',
        marginBottom: '4px',
    },
    removeTask: {
        marginLeft: '4px',
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        fontSize: '12px',
    },
    placeholder: {
        color: '#6c757d',
    },
    dropdownOptions: {
        position: 'absolute',
        top: '100%',
        left: '0',
        right: '0',
        zIndex: '1000',
        backgroundColor: '#fff',
        border: '1px solid #ced4da',
        maxHeight: '200px',
        overflowY: 'auto',
        borderRadius: '4px',
    },
    dropdownOption: {
        padding: '8px 12px',
        cursor: 'pointer',
    },
    dropdownOptionHover: {
        backgroundColor: '#f8f9fa',
    }
};

export default EditModule;
