import React, { useState, useEffect } from 'react';
import { taskUpdateAPI } from 'src/api/TaskApi';

const EditTask = ({ taskId, onTaskSaved }) => {
    const [userId, setUserId] = useState(null);
    const [taskname, setTaskName] = useState(taskId?.taskName || '');
    const [target, setTarget] = useState(taskId?.target || '');
    const [unit, setUnit] = useState('');
    const [item, setItem] = useState('');

    useEffect(() => {
        // Update the state with the new task's details whenever taskId changes
        if (taskId) {
            setTaskName(taskId.taskName || '');
            setTarget(taskId.target || '');
            setItem(taskId.item || '');
            setUnit(taskId.unit || '');
        }
    }, [taskId]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (storedUser) {
            setUserId(storedUser?._id);
        }
    }, []);

    const updateTask = async () => {
        let data = {
            taskName: taskname,
            target: target,
            unit: unit,
            item: item,
            by: userId
        }
        console.log('body of save taskk', data);
        try {
            const resp = await taskUpdateAPI({ taskId:taskId?._id, data: data });
            console.log('response from the update task', resp?.data);
            if (onTaskSaved) {
                onTaskSaved();
            }
        } catch (error) {
            console.log('error from the update task', error);
        }
    }

    const handleChangeItem = (e) => {
        const selectedItem = e.target.value;
        setItem(selectedItem);

        if (selectedItem === 'Daily Target') {
            setUnit('Per Day');
        } else if (selectedItem === 'Bound' || selectedItem === 'Open') {
            setUnit('Min');
        } else {
            setUnit('');
        }
    };

    return (
        <div
            className="offcanvas offcanvas-end filterSecPopup"
            id="EditTaskPopup"
            aria-labelledby="editTaskSec"
        >
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="addNewTaskSec">
                    Edit Task
                </h5>
                <button
                    type="button"
                    className="btn-close d-none"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                />
            </div>
            <div className="offcanvas-body pt-0">
                <div className="assignPopoHeight">
                    <div className="selectSec pt-0">
                        <label htmlFor="">Task</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Task Name"
                            value={taskname}
                            onChange={(e) => { setTaskName(e.target.value) }}
                        />
                    </div>
                    <div id="addTaskMoudalCantainer" />
                    <div className="selectSec pt-0">
                        <label htmlFor="">Target</label>
                        <div className="costSec">
                            <div className="costinputSec">
                                <input
                                    type="number"
                                    min={0}
                                    defaultValue={0}
                                    className="w-100"
                                    value={target}
                                    onChange={(e) => { setTarget(e.target.value) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="selectSec filterStyleSec pt-2">
                        <label htmlFor="">Item</label>
                        <select
                            value={item}
                            onChange={handleChangeItem}
                            style={{ width: '100%', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ced4da' }}
                        >
                            <option value="">Select Item</option>
                            <option value={'Daily Target'}>Daily Target</option>
                            <option value={'Bound'}>Bound</option>
                            <option value={'Open'}>Open</option>
                        </select>
                    </div>
                    <div className="selectSec filterStyleSec pt-2">
                        <label htmlFor="">Unit</label>
                        <input
                            type="text"
                            className="form-control"
                            value={unit}
                            readOnly
                            style={{ backgroundColor: '#e9ecef', cursor: 'not-allowed' }}
                        />
                    </div>
                </div>
                <div className="">
                    <button
                        type="button"
                        className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
                        onClick={updateTask}
                    >
                        Update
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditTask;
