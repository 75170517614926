import { combineReducers } from "redux";
import HomeReducer from './containers/home-container/reducer/HomeReducer';
import authReducer  from "./containers/home-container/reducer/AuthReducer"
// import AccountReducer  from "./containers/account-container/reducer/AccountReducer"
import ClientReducer from './containers/client-container/reducer/ClientReducer'; 
import ProductionReducer from './containers/production-container/reducer/ProductionReducer'; 
import ProjectReducer from './containers/project-container/reducer/ProjectReducer'; 
import TaskReducer from './containers/project-container/Task/reducer/TaskReducer'; 
import ModuleReducer from './containers/project-container/Module/reducer/ModuleReducer';
import ClientProductionReducer from "./containers/project-container/View/ClientDeliveryWise/reducer/ClientProductionReducer";
 

export default combineReducers({
    HomeReducer,
    auth: authReducer,
    // AccountReducer,
    ClientReducer,
    ProjectReducer,
    TaskReducer,
    ModuleReducer,
    ProductionReducer,
    ClientProductionReducer
});
