import { useCallback, useState } from "react"
import ModuleTree from "./ModuleTree"
import { useDispatch } from "react-redux"
import { getModuleByTaskFromAPI } from "src/api/UserApi"
import {productionAssignTaskIdAction } from "../actions/ProductionActions"
const TaskTree=({task})=>{
    const [isOpen,setIsOpen] = useState(false)
    const [modules,setModules] = useState([])
    const dispatch = useDispatch();
    const handleLoadModuleByProject = useCallback(async(e,taskDetails)=>{
        e.stopPropagation(); // Prevent event propagation
        setIsOpen(!isOpen)
        const module = await getModuleByTaskFromAPI(taskDetails._id)
        setModules(module.data.data)
        dispatch(productionAssignTaskIdAction({
          data:taskDetails._id
        }));
      },[task])
  return (
    <> 
             <li 
             className={`has-children ${isOpen ? 'is-open' : ''}`}
             onClick={(e) => {handleLoadModuleByProject(e,task)}}
             >
                        <div className="acnav__label acnav__label--level6">
                          {task.taskName}
                        </div>
                        {/* start level 4 */}
                        <ul className="acnav__list acnav__list--level5">
                          <li>
                            <a
                              className="proHeadColoPink acnav__link acnav__link--level7"
                              href="javascript:void(0)"
                            >
                              Select Module
                            </a>
                          </li>
                        {
                         modules.map((module,moduleIndex)=>{
                                return(
                                   <ModuleTree module={module} key={moduleIndex}/>
                                )
                            })
                        }
                        </ul>
                        {/* end level 4 */}
                 </li>



    </>
  )
}
export default TaskTree