import React, { useState, useEffect } from 'react';
import FilterPopup from './FilterPopup';
import ManageColumn from './ManageColumn';
import UpsertTask from './UpsertTask';
import TaskModuleTab from '../TaskModuleTab';
import { taskSearchAPI, taskStatusUpdateAPI } from 'src/api/TaskApi';
import { useParams } from 'react-router-dom';
import EditTask from './EditTask';
import BackProjectButton from 'src/components/BackProjectButton';
import DatePickerComponent from 'src/components/DatePickerComponent';

const TaskContainer = () => {

  const { projectId } = useParams();
  const [tasks, setTasks] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [statuses, setStatuses] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedStatuses, setSelectedStatuses] = useState([]);


  const searchTask = async () => {
    let body = {
      pageLength: 100,

      where: {
        date: {
          $gte: startDate || null,
          $lte: endDate || null,
        },
        taskStatus: selectedStatuses.length > 0 ? { $in: selectedStatuses } : undefined,
        projectId: projectId
      },
    };

    try {
      const resp = await taskSearchAPI(body);
      console.log('API Response:', resp); // Log the entire response
      console.log('Filtered Data:', resp.data.data.data); // Log the data array specifically

      const filteredData = resp.data.data.data.filter((task) => {
        const userDate = new Date(task.ts);
        const isWithinDateRange =
          (!startDate || userDate >= new Date(startDate)) &&
          (!endDate || userDate <= new Date(endDate));
        const matchesSearch = `${task.taskName}`
          .toLowerCase()
          .includes(search.toLowerCase());

        // Check if any of the selected statuses are in the taskStatus array
        const matchesStatus = selectedStatuses.length === 0 || selectedStatuses.some(status => task.taskStatus.includes(status));
        console.log('Task Status:', task.taskStatus, 'Matches Filter:', matchesStatus); // Log each status check

        return isWithinDateRange && matchesSearch && matchesStatus;
      });

      if (filteredData.length === 0) {
        console.warn('No tasks matched the filter criteria.');
      }

      setTasks(filteredData);
    } catch (error) {
      console.error('Error fetching task data', error);
    }
  };




  useEffect(() => {
    searchTask();
  }, [search, startDate, endDate, selectedStatuses]);

  const handleEditButtonClick = (taskId) => {
    console.log('Clicked task id:', taskId);
    setSelectedTaskId(taskId);
    console.log('Updated selectedTaskId:', taskId);
  };

  const handleStatusChange = async (taskId, newStatus) => {
    setStatuses(prev => ({ ...prev, [taskId]: newStatus }));

    let body = {
      taskStatus: newStatus
    }
    try {
      const resp = await taskStatusUpdateAPI({ taskId, data: body });
      console.log('Response from status update:', resp);
      searchTask()
    } catch (error) {
      console.log('Error updating status:', error);
    }
    console.log('Updated status for task id:', taskId, 'New status:', newStatus);
  }

  // Function to get the style based on status
  const getStatusStyle = (status) => {
    switch (status) {
      case 'Pending':
        return { backgroundColor: 'orange', color: 'white' };
      case 'Approved':
        return { backgroundColor: 'green', color: 'white' };
      case 'Rejected':
        return { backgroundColor: 'red', color: 'white' };
      default:
        return { backgroundColor: 'transparent', color: 'black' };
    }
  };

  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setStartDate(selectedDates[0]);
      setEndDate(selectedDates[1]);
    } else {
      setStartDate('');
      setEndDate('');
    }
  }

  const handleFilterChange = (statuses) => {
    setSelectedStatuses(statuses); // Update selected statuses state
  };

  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
               <BackProjectButton/>
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                    <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Task Client Details</li>
              </ul>
              <TaskModuleTab />
              <div className="d-flex gap-2 align-items-center py-16">
                {/* Search start */}
                <div className="search">
                  <span className="searchBtn position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512">
                      <path fill="none" stroke="currentColor" strokeMiterlimit={10} strokeWidth={32} d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"></path>
                      <path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={32} d="M338.29 338.29L448 448"></path>
                    </svg>
                  </span>
                  <input type="text"
                    className="form-control radius-8"
                    id="search"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}
                  />
                </div>
                {/* Search End */}
                {/* Date Range picker start  */}
                <DatePickerComponent onDateRangeChange={handleDateRangeChange} />
                {/* <div className="datepker position-relative">
                  <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <iconify-icon icon="solar:calendar-linear" className="icon text-lg" />
                  </span>
                  <input className="form-control radius-8 bg-base " type="date" id="flat" placeholder="Date" />
                </div> */}
                {/* Date Range picker end  */}
                {/* Manage Column btn start */}
                <div className="FilterBtn">
                  {/* <button type="button" data-bs-toggle="offcanvas" data-bs-target="#manageColRightPopup" aria-controls="manageColRightPopup" className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 text-sm radius-8 px-20 py-9 d-flex align-items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-columns" viewBox="0 0 16 16">
                      <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm8.5 0v8H15V2zm0 9v3H15v-3zm-1-9H1v3h6.5zM1 14h6.5V6H1z" />
                    </svg>{" "}
                    Manage Column
                  </button> */}
                </div>
                {/* Manage Column btn end */}
                {/* Filter btn start */}
                <div className="FilterBtn">
                  <button type="button" data-bs-toggle="offcanvas" data-bs-target="#filterRightPopup" aria-controls="filterRightPopup" className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 radius-8 px-20 py-9 d-flex align-items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.5em" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z" />
                    </svg>{" "}
                    Filter
                  </button>
                </div>
                {/* Filter btn end */}
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-neutral-900">Task Details</h6>
                <a href="" className="btn btn-sm btn-primary-600" data-bs-toggle="offcanvas" data-bs-target="#AddNewTaskPopup" aria-controls="AddNewTaskPopup">
                  <i className="ri-add-line" /> Add Task
                </a>
              </div>
              <div className="table-responsive table-container" id="table-container">
                <table className="table basic-border-table mb-0 table-hover">
                  <thead>
                    <tr className="dnd-moved">
                      <th>
                        <input className="form-check-input" type="checkbox" />
                      </th>
                      <th>#</th>
                      <th>Task Name</th>
                      <th>Unit</th>
                      <th>Item</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tasks?.map((task, index) => (
                      <tr key={task.id} className="dnd-moved">
                        <td>
                          <input className="form-check-input" type="checkbox" />
                        </td>
                        <td>{index + 1}</td>
                        <td>{task.taskName}</td>
                        <td>{task.unit}</td>
                        <td>{task.item}</td>
                        <td className="text-center">
                          <select
                            value={statuses[task?._id] || task?.taskStatus}
                            onChange={(e) => handleStatusChange(task?._id, e.target.value)}
                            className="form-select form-select-sm"
                            style={{ width: '120px', ...getStatusStyle(statuses[task?._id] || task.taskStatus) }}
                          >
                            <option value="Pending">Pending</option>
                            <option value="Rejected">Rejected</option>
                            <option value="Approved">Approved</option>
                          </select>
                        </td>
                        <td>
                          <a
                            className="btn btn-sm btn-primary me-2"
                            onClick={() => handleEditButtonClick(task)}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#EditTaskPopup"
                            aria-controls="EditTaskPopup"
                          >
                            Edit
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ManageColumn />
      <FilterPopup onFilterChange={handleFilterChange} />
      <UpsertTask projectId={projectId} onTaskSaved={searchTask} />
      {selectedTaskId && <EditTask taskId={selectedTaskId} onTaskSaved={searchTask} />}
    </>
  );
};

export default TaskContainer;
