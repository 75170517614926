
// import { createTheme } from "@mui/material/styles";
import moment from "moment";
export const isHtmlTag = (str) => {
  const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  return htmlRegex.test(str);
};

export const isTruthy = (value) => {
  return value !== null && value !== undefined && value !== 0 && value !== "";
};

export const checkIfLink = (message) => {
  const urlRegex = /(https?:\/\/[^\s]+)/;
  if (urlRegex.test(message)) {
    return true;
  } else {
    return false;
  }
};
const imageExtensions = [
  "jpeg",
  "jpg",
  "png",
  "gif",
  "bmp",
  "tiff",
  "tif",
  "svg",
  "webp",
  "heic",
  "heif",
];
export const getFileType = (media) => {
  const fileUrl = media.url;

  if (
    (checkIfLink(media.message) && fileUrl === null) ||
    fileUrl === undefined
  ) {
    return "unknown";
  }
  if (fileUrl) {
    const extension = fileUrl.split(".").pop().toLowerCase();
    const extension2 = media?.fileType?.split("/")[1];
    if (
      imageExtensions.includes(extension) ||
      imageExtensions.includes(extension2)
    ) {
      return "image";
    } else if (
      extension === "mp3" ||
      extension === "wav" ||
      extension === "ogg" ||
      extension === "m4a" ||
      extension === "opus" ||
      extension === "blob"
    ) {
      return "audio";
    } else if (
      extension === "mp4" ||
      extension === "avi" ||
      extension === "mkv" ||
      extension === "mov"
    ) {
      return "video";
    } else if (
      extension === "pdf" ||
      extension === "doc" ||
      extension === "docx" ||
      extension === "txt" ||
      [
        "zip",
        "rar",
        "7z",
        "tar",
        "gzip",
        "gz",
        "docx",
        "xlsx",
        "csv",
        "ppt",
        "pptx",
        "json",
      ].includes(extension)
    ) {
      return "document";
    } else if (checkIfLink(media.message)) {
      return "link";
    } else {
      return "unknown";
    }
  }
};

export const formatDate = (timestamp) => {
  return moment.utc(new Date(timestamp)).format("DD/MM/YYYY");
};
export const formatTime = (timestamp) => {
  return moment.utc(new Date(timestamp)).format("HH:MM:SS");
};
export const getDateFromDate = (date) => {
  return moment(date).format("DD");
};
export const getDayFromDate = (date) => {
  return moment(date).format("ddd"); // 'ddd' gives the abbreviated name of the day, e.g., 'Mon'
};
export const formatDateToMon = (dateString) => {
  const months = [
    "JAN",
    "FAB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  return `${day} ${month}  `;
};

function isSameDateTime(date, time) {
  const [timePart, amOrPm] = time.split(" ");
  const [hours, minutes] = timePart.split(":").map(Number);

  let adjustedHours = hours;
  if (amOrPm === "pm" && hours !== 12) {
    adjustedHours += 12;
  } else if (amOrPm === "am" && hours === 12) {
    adjustedHours = 0;
  }
  const [year, month, day] = date.split("-").map(Number);
  const reminderDate = new Date(year, month - 1, day, adjustedHours, minutes);
  const now = new Date();

  const timeDiff = reminderDate.getTime() - now.getTime();
  return timeDiff <= 0;
}

// export const theme = createTheme({
//   components: {
//     MuiOutlinedInput: {
//       styleOverrides: {
//         root: {
//           "& $notchedOutline": {
//             "& .MuiOutlinedInput-notchedOutline": {
//               borderWidth: 1,
//             },
//           },
//         },
//       },
//     },
//   },
// });

export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const convertTimestampToDdMmYyyy = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
export const unAcceptedExtensions = [
  ".bat",
  ".cmd",
  ".com",
  ".msi",
  ".ps1",
  ".sh",
  ".bash",
  ".py",
  ".rb",
  ".pl",
  ".js",
  ".vbs",
  ".wsf",
  ".hta",
  ".docm",
  ".xlsm",
  ".pptm",
  ".dll",
  ".so",
  ".dylib",
  ".pif",
  ".cpl",
  ".sys",
  ".eml",
  ".msg",
];

export const makeUniqueById = (array) => {
  const map = new Map();
  array.forEach((obj) => map.set(obj._id, obj));
  return [...map.values()];
};
export const titleCase = (str, isCamel = false) => {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  if (isCamel) return str.join("");
  else return str.join(" ");
};


export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

export const CurrentYear = () => {
  const year = new Date().getFullYear();
  return year;
};

