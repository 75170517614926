import config from "src/config";
import { postRequest } from "src/utils/RequestUtil";
export const handleLogin = async ( values) => {
  try {
    const url = `${config.baseUrl}auth/sign-in`;
    return await postRequest({
      url,
      data: { ...values },
    });
  } catch (error) {
    throw error;
  }
};

export const handleLogout = async () => {
  try {
    if (window.electron && window.electron.setBadgeCount) {
      window.electron.setBadgeCount(0);
    }
    const url = `${config.baseUrl}auth/logout/single/device`;
    return postRequest({
      url,
      data: {},
    });
  } catch (error) {
    throw error;
  }
};
