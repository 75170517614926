import { shallowEqual, useSelector } from "react-redux";

const GroupCounter=(props)=>{
  const {groupCounter} = useSelector((state)=>state.ProductionReducer,shallowEqual);
    return(
    <>
    <div className="row row-cols-xxxl-5 row-cols-xl-5 row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-1 gy-4">
                  <div className="col">
                    <div className="card shadow-none h-100 totproild Idle-border rounded-0">
                      <div className="card-body p-20 textresponSec">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                          <div>
                            <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                              Total Idle
                            </p>
                            <p className="mb-0 totltm text-neutral-900 fw-semibold">
                              {groupCounter.totalIDLE.total}{" "}
                              <span className="text-neutral-400 fw-normal">
                                /{groupCounter.totalIDLE.outOf}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card end */}
                  </div>
                  <div className="col">
                    <div className="card shadow-none h-100 totproild Hours-border rounded-0">
                      <div className="card-body p-20 textresponSec">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                          <div>
                            <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                              Total Working Hours{" "}
                            </p>
                            <p className="mb-0 totltm text-neutral-900 fw-semibold">
                              {groupCounter.totalWorkingHour?.total}{" "}
                              <span className="text-neutral-400 fw-normal">
                                /{groupCounter.totalWorkingHour?.outOf}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card end */}
                  </div>
                  <div className="col">
                    <div className="card shadow-none h-100 totproild Accepted-border rounded-0">
                      <div className="card-body p-20 textresponSec">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                          <div>
                            <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                              Accepted
                            </p>
                            <p className="mb-0 totltm text-neutral-900 fw-semibold">
                              {groupCounter.totalAccepted?.total}{" "}
                              <span className="text-neutral-400 fw-normal">/{groupCounter.totalAccepted?.outOf}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card end */}
                  </div>
                  <div className="col">
                    <div className="card shadow-none h-100 totproild Rejected-border rounded-0">
                      <div className="card-body p-20 textresponSec">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                          <div>
                            <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                              Rejected
                            </p>
                            <p className="mb-0 totltm text-neutral-900 fw-semibold">
                            {groupCounter.totalRejected?.total}{" "}
                              <span className="text-neutral-400 fw-normal">/{groupCounter.totalRejected?.outOf}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card end */}
                  </div>
                  <div className="col">
                    <div className="card shadow-none h-100 totproild Pending-border rounded-0">
                      <div className="card-body p-20 textresponSec">
                        <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                          <div>
                            <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                              Pending
                            </p>
                            <p className="mb-0 totltm text-neutral-900 fw-semibold">
                            {groupCounter.totalPending?.total}{" "}
                              <span className="text-neutral-400 fw-normal">/{groupCounter.totalPending?.outOf}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* card end */}
                  </div>
                </div>
    </>
    )
}

export default GroupCounter