import React from 'react';
import { Routes, Route } from "react-router-dom";
import SkillWiseContainer from './SkillWise/Index';
import TaskWiseContainer from './TaskWise/index';
import IndividualUserContainer from './Individual/Index';
import ModuleWiseContainer from './ModuleWise/Index';
import ClientDeliveryWiseContainer from './ClientDeliveryWise/index';
import ProjectProductionContainer from './Production/Index';
import PAndLContainer from './PAndL/Index';


const ViewContainer = () => {
  return (
    <>
      <Routes>
        {/* <Route index element={<SkillWiseContainer  />} />  */}
        <Route path="skill/:projectId" element={<SkillWiseContainer />} />
        <Route path="task/:projectId" element={<TaskWiseContainer />} />
        <Route path="individual/:projectId" element={<IndividualUserContainer />} />
        <Route path="client-delivery/:projectId" element={<ClientDeliveryWiseContainer />} />
        <Route path="module/:projectId" element={<ModuleWiseContainer />} />
        <Route path="production/:projectId" element={<ProjectProductionContainer />} />
        <Route path="p-and-l/:projectId" element={<PAndLContainer />} />
      </Routes>
    </>
  )
}

export default ViewContainer