export const PROJECT_ACTIONS = {
    PROJECT_SEARCH: 'PROJECT_SEARCH',
    PROJECT_SEARCH_SUCCESS: 'PROJECT_SEARCH_SUCCESS',
    PROJECT_SEARCH_ERROR: 'PROJECT_SEARCH_ERROR',

    PROJECT_UPSERT: 'PROJECT_UPSERT',
    PROJECT_UPSERT_SUCCESS: 'PROJECT_UPSERT_SUCCESS',
    PROJECT_UPSERT_ERROR: 'PROJECT_UPSERT_ERROR',

    PROJECT_EDIT: 'PROJECT_EDIT',

    PROJECT_RESET: 'PROJECT_RESET',
};

export const projectResetAction = () => ({
    type: PROJECT_ACTIONS.PROJECT_RESET,
});

export const projectSearchAction = (payload) => ({
    type: PROJECT_ACTIONS.PROJECT_SEARCH,
    ...payload,
});

export const projectEditAction = (payload) => ({
    type: PROJECT_ACTIONS.PROJECT_EDIT,
    ...payload,
});

export const projectUpsertAction = (payload) => ({
    type: PROJECT_ACTIONS.PROJECT_UPSERT,
    ...payload,
});

export default {
    PROJECT_ACTIONS,
    projectSearchAction,
    projectUpsertAction,
    projectEditAction,
};
