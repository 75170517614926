import { shallowEqual, useDispatch, useSelector } from "react-redux"
import TopTabs from "../TopTabs"
import AddClientDelivery from "./AddClientDelevery"
import ClientProductionList from "./ClientProductionList"
import ClientDeliveryFilter from "./FIlter"
import ManageColum from "./ManageColoum"
import { useCallback, useEffect, useState } from "react"
import { clientProductionSearchAction } from "./actions/ClientProductionActions"
import ClientCommentModal from "./ClientCommentModal"


const ClientDeliveryWiseContainer = () => {
  const { search: clientProductionSearch } = useSelector((state) => state.ClientProductionReducer, shallowEqual);
  const { _id: userId } = useSelector(state => state.auth?.user, shallowEqual);
  const { selectedMonth, selectedYear } = useSelector(state => state.ProductionReducer, shallowEqual);
  const dispatch = useDispatch();
  const [where, setWhere] = useState({
    userId,
    $expr: {
      $and: [
        { $eq: [{ $year: "$date" }, selectedYear] },
        { $eq: [{ $month: "$date" }, selectedMonth] }
      ]
    }
  });
  const totalAmount = clientProductionSearch.data.reduce((sum, transaction) => {
    return sum + transaction.cast || 0;
  }, 0);


  useEffect(() => {
    search(where);
  }, [
    dispatch,
    where
  ]);
  const search = useCallback(() => {
    dispatch(clientProductionSearchAction({
      where: where
    }));
  }, [dispatch, where])

  const handleChangeDate = (date) => {
    // console.log("date",,);
    const year = date.getFullYear();  // Example year
    const month = date.getMonth() + 1;  // Since month is 0-indexed in JavaScript, add 1
    setWhere({
      ...where,
      $expr: {
        $and: [
          { $eq: [{ $year: "$date" }, year] },
          { $eq: [{ $month: "$date" }, month] }
        ]
      }
    })
  }

  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width={30}
                    height={30}
                  >
                    <g data-name="20-Arrow Left">
                      <path
                        fill="#0073ea"
                        d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"
                      />
                      <path
                        fill="#0073ea"
                        d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"
                      />
                    </g>
                  </svg>
                </button>{" "}
                AplusXpert
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="javascript:void(0)"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Client Delivery </li>
              </ul>
              <TopTabs />
              <div className="d-flex gap-2 align-items-center py-16">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addClientDelivery"
                  className="btn btn-primary text-sm heightAllBtnSet44 btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2"
                >
                  <iconify-icon
                    icon="ic:baseline-plus"
                    className="icon text-xl line-height-1"
                  ></iconify-icon>
                  Add Client Delivery
                </a>
                {/* Search start */}
                <div className="search">
                  <span className="searchBtn position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
                      ></path>
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M338.29 338.29L448 448"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control radius-8"
                    id="search"
                    placeholder="Search"
                  />
                </div>
                {/* Search End */}
                {/* Date Range piker start  */}
                <div className="datepker position-relative">
                  <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <iconify-icon
                      icon="solar:calendar-linear"
                      className="icon text-lg"
                    />
                  </span>
                  <input
                    className="form-control radius-8 bg-base"
                    type="date"
                    id="flat"
                    placeholder="Date"
                  />
                </div>
                {/* Date Range piker end  */}
                {/* Filter btn start */}
                {/* <div className="FilterBtn">
            <button
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#filterRightPopup"
              aria-controls="filterRightPopup"
              className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 radius-8 px-20 py-9 text-sm d-flex align-items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="1.5em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
                />
              </svg>{" "}
              Filter
            </button>
          </div> */}
                {/* Filter btn end */}
                {/* Manage Column btn start */}
                {/* <div className="FilterBtn">
            <button
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#manageColRightPopup"
              aria-controls="manageColRightPopup"
              className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 text-sm radius-8 px-20 py-9 d-flex align-items-center gap-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                fill="currentColor"
                className="bi bi-columns"
                viewBox="0 0 16 16"
              >
                <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm8.5 0v8H15V2zm0 9v3H15v-3zm-1-9H1v3h6.5zM1 14h6.5V6H1z" />
              </svg>{" "}
              Manage Column
            </button>
          </div> */}
                {/* Manage Column btn end */}
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-primary-900 d-flex align-items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-stack"
                    viewBox="0 0 16 16"
                  >
                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z" />
                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z" />
                  </svg>{" "}
                  Project Code_315B
                </h6>
                {/* <button class="bg-success-focus text-success-main px-16 py-4 radius-4 fw-medium text-sm">Completed</button> */}
                {/* <button
            type="button"
            className="btn btn-neutral-100 text-primary-light px-16 py-8 radius-6 fw-medium text-sm d-flex align-items-center gap-2"
          >
            Export
            <img
              src="assets/images/left-sidebar/xls-icon.svg"
              className="img-fluid"
            />
          </button> */}
              </div>
              <div className="table-responsive table-container" id="table-container">
                <table className="table basic-border-table mb-0 table-hover">
                  <thead>
                    <tr className="dnd-moved">
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th>Actual Date </th>
                      <th>Delivery Date </th>
                      <th>Uploaded by </th>
                      <th>Department </th>
                      <th className="text-center">Cadre </th>
                      <th className="text-center">Count </th>
                      <th className="text-center">Amount </th>
                      <th className="text-center">File </th>
                      <th className="text-center">Comment </th>
                    </tr>
                  </thead>
                  <tbody id="table-body">
                    <ClientProductionList />
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2} />
                      <td colSpan={5} className="fw-bold">
                        Total
                      </td>
                      <td className="text-center">
                        <span className="d-block fw-bold">₹{totalAmount}</span> Total Cost
                      </td>
                      <td colSpan={3} className="fw-bold" />
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClientDeliveryFilter />
      <AddClientDelivery />
      <ManageColum />
      <ClientCommentModal />
    </>
  )
}
export default ClientDeliveryWiseContainer