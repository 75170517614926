import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { withFormikDevtools } from 'formik-devtools-extension';
import config from "src/config";
import { postRequest, putRequest } from 'src/utils/RequestUtil';
import Loading from '../main-container/Loading';
import FileUpload from 'src/components/FileUpload';
import { ClientDefaultProps, ClientYupSchema } from './ClientPropTypes';
import { isProduction } from 'src/config/index';
import PropTypes from 'prop-types';
import FormikInput from 'src/components/formik/FormikInput';
import FormikTextarea from 'src/components/formik/FormikTextarea';
import FormikMySelect from 'src/components/formik/FormikMySelect';
import AssignTask from './AssignTask';
import ManageColum from './ManageColum';
import FilterPopup from './FilterPopup';
import ClientList from './ClientList';
import { useDispatch } from 'react-redux';
import { clientSearchAction } from './actions/ClientActions';
import ClientStatusCounter from './ClientStatusCounter';
import SearchInput from 'src/components/SearchInput';

const Render = (props) => {

  if (!isProduction()) {
    withFormikDevtools(props);
  }

  const {
    isSubmitting,
  } = props;

  const serviceOptions = [
    { value: 'contentDevelopment', label: 'Content Development' },
    { value: 'eLearningServices', label: 'eLearning Services' },
    { value: 'accessibility', label: 'Accessibility' },
    { value: 'editorialPrePress', label: 'Editorial & Pre Press' },
    { value: 'videoSolutions', label: 'Video Solutions' },
  ];
  return (
    <Form autoComplete="disabled">
      <div className="assignPopoHeight">
        <div className="selectSec pt-0">
          <label htmlFor="ClientRegisteredName">Registered Name *</label>
          <FormikInput
            type="text"
            name="ClientRegisteredName"
            className="form-control"
            placeholder="Registered Name"
          />
        </div>
        <div className="selectSec pt-0">
          <label htmlFor="">Commercial Name *</label>
          <FormikInput
            type="text"
            name="ClientCommercialName"
            className="form-control"
            placeholder="Enter Commercial Name"
          />
        </div>
        <div className="selectSec pt-0">
          <label htmlFor="">GST</label>
          <FormikInput
            type="text"
            name="ClientGST"
            className="form-control"
            placeholder="eg, 22AAAAA0000A1Z5"
          />
        </div>
        <div className="selectSec pt-0">
          <label htmlFor="">Address *</label>
          <FormikTextarea
            name="ClientAddress"
            className="form-control"
            rows={4}
            cols={50}
            placeholder="Enter Address"
            defaultValue={""}
          />
        </div>
        <div className="selectSec pt-0">
          <label htmlFor="">Website</label>
          <FormikInput
            type="text"
            name="ClientWebsite"
            className="form-control"
            placeholder="www.website.com"
          />
        </div>
        <div className="selectSec droupPosiSet">
          <label htmlFor="" className="">
            Service
          </label>
          <FormikMySelect
            name="ClientService"
            options={serviceOptions}
            placeholder="Select Services"
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
        <div className="selectSec pt-0">
          <label htmlFor="">Phone No.</label>
          <FormikInput
            type="text"
            name="ClientPhoneNo"
            className="form-control"
            placeholder="Enter Phone No"
          />
        </div>





        <FileUpload />
      </div>
      <div className="">
        <button
          type="submit"
          className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
          disabled={isSubmitting}
        >
          Save
        </button>
      </div>
    </Form>
  );
};

Render.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
};

let setSubmitting = () => { };

const ClientContainer = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [groupCounter, setGroupCounter] = useState({clientCount:0,projectCount:0,ProfitAndLoss:0});
  const [projectStatus, setProjectStatus] = useState([]);
  const [profitAndLoss,setProfitAndLoss] = useState([])

  useEffect(() => {
    setIsLoading(true);
    let isMounted = true
    dispatch(clientSearchAction())
    getClientList()
    getUserList()

    return () => {
      isMounted = false;
    }
  }, []);
  const getClientList = async () => {
    const response = await postRequest({
      url: `${config.baseUrl}client/get`
    })
    const profitAndLossResponse = await postRequest({
      url: `${config.baseUrl}client/clientProfitAndLoss`
    })
    setProfitAndLoss(profitAndLossResponse.data.data)
     
    setClients(response.data.data);
    setGroupCounter({
      ...groupCounter,
      clientCount:response.data.clientCount,
      projectCount:response.data.projectCount,
      ProfitAndLoss:response.data.ProfitAndLoss
    })
    console.log(response.data.projectStatusCount)
    setProjectStatus([...response.data.projectStatusCount])
    setIsLoading(false);
  }

  const [users, setUsers] = useState('');
  const getUserList = async (where = {}) => {
    const response = await postRequest({
      url: `${config.baseUrl}user/get`,
      data: { where }
    })
    setUsers(response.data.data);
  }


  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    try {
      const url = `${config.baseUrl}client/save`;
      const result = await postRequest({
        url,
        data: values,
      });
      console.log(result);
      await getClientList();
      dispatch(clientSearchAction())
      toast.success('Client submitted successfully!');
      resetForm(); // Reset the form after successful submission
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit form.');
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredData = clients.filter(item =>
    item.ClientProjectCode.toLowerCase().includes(searchTerm) ||
    item.ClientPhoneNo.toLowerCase().includes(searchTerm) ||
    item.ClientRegisteredName.toLowerCase().includes(searchTerm) ||
    item.ClientCommercialName.toLowerCase().includes(searchTerm) ||
    item.ClientWebsite.toLowerCase().includes(searchTerm)
  );

  const [edit, setEdit] = useState({ clientId: null, projectId: null, ProjectExpected: '' });
  const [edit3, setEdit3] = useState({ clientId: null, projectId: null, RatePerFile: '' })
  console.log('project id', edit3);

  const handleEditClick = (clientId, projectId, value) => {
    setEdit({ clientId, projectId, ProjectExpected: value });
  };

  const handleEditClick1 = (clientId, projectId, value) => {
    setEdit3({ clientId, projectId, RatePerFile: value });
  };

  const handleInputChange = (e) => {
    setEdit(prev => ({ ...prev, ProjectExpected: e.target.value }));
  };

  //// for RatePerFile //////
  const handleInputChange1 = (e) => {
    setEdit3(prev => ({ ...prev, RatePerFile: e.target.value }));
  };

  const handleSaveClick = async () => {

    setIsLoading(true);
    try {
      const url = `${config.baseUrl}project/update/${edit.projectId}`;
      const result = await putRequest({
        url,
        data: edit,
      });
      console.log(result);
      toast.success('Update successfully!');
    } catch (error) {
      toast.error('Failed to save.');
    } finally {
      setIsLoading(false);
    }

    setClients(clients.map(client =>
      client._id === edit.clientId ? {
        ...client,
        projects: client.projects.map(project =>
          project._id === edit.projectId ? { ...project, ProjectExpected: Number(edit.ProjectExpected) } : project
        )
      } : client
    ));
    handleCancelClick()
  };

  ///// for RatePerFile /////

  const handleSaveClick1 = async () => {

    setIsLoading(true);
    try {
      const url = `${config.baseUrl}project/update/${edit3.projectId}`;
      const result = await putRequest({
        url,
        data: edit3,
      });
      console.log(result);
      toast.success('Update successfully!');
    } catch (error) {
      toast.error('Failed to save.');
    } finally {
      setIsLoading(false);
    }

    setClients(clients.map(client =>
      client._id === edit3.clientId ? {
        ...client,
        projects: client.projects.map(project =>
          project._id === edit3.projectId ? { ...project, RatePerFile: Number(edit3.RatePerFile) } : project
        )
      } : client
    ));
    handleCancelClick1()
  };


  const handleCancelClick = () => {
    setEdit({ clientId: null, projectId: null, ProjectExpected: '' });
  };

  const handleCancelClick1 = () => {
    setEdit3({ clientId: null, projectId: null, RatePerFile: '' });
  };
  const [edit2, setEdit2] = useState({ clientId: null, projectId: null, ProjectStatus: '' });

  const handleEditStatusClick = (clientId, projectId, value) => {
    setEdit2({ clientId, projectId, ProjectStatus: value });
  };
  const handleInputStatusChange = async (ProjectStatus) => {
    setEdit2(prev => ({ ...prev, ProjectStatus: ProjectStatus }));
    console.log(edit2, 'wew', ProjectStatus)
    console.log('Clicked item text:', edit2.clientId, edit2.projectId, { "ProjectStatus": ProjectStatus });
    setIsLoading(true);
    try {
      const url = `${config.baseUrl}project/update/${edit2.projectId}`;
      const result = await putRequest({
        url,
        data: { "ProjectStatus": ProjectStatus },
      });
      console.log(result);
      toast.success('Update successfully!');
    } catch (error) {
      toast.error('Failed to save.');
    } finally {
      setIsLoading(false);
    }
    setClients(clients.map(client =>
      client._id === edit2.clientId ? {
        ...client,
        projects: client.projects.map(project =>
          project._id === edit2.projectId ? { ...project, ProjectStatus: ProjectStatus } : project
        )
      } : client
    ));
    // e.target.closest('ul').classList.remove('show');
    setEdit2({ clientId: null, projectId: null, ProjectStatus: '' })
  };

  const handleNewProject = async (clientId) => {
    setIsLoading(true);
    try {
      const url = `${config.baseUrl}client/add-project`;
      const result = await postRequest({
        url,
        data: { clientId: clientId },
      });
      console.log(result);
      await getClientList();
      dispatch(clientSearchAction())
      toast.success('Project submitted successfully!');
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit form.');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading && <Loading />}
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <div className="d-flex justify-content-between align-items-center border-bottom  pb-16">
                <div className="">
                  <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                    <button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        width={30}
                        height={30}
                      >
                        <g data-name="20-Arrow Left">
                          <path
                            fill="#0073ea"
                            d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"
                          />
                          <path
                            fill="#0073ea"
                            d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"
                          />
                        </g>
                      </svg>
                    </button>{" "}
                    My Clients
                  </h2>
                  <ul className="d-flex align-items-center gap-2">
                    <li className="fw-medium">
                      <a
                        href="index-2.html"
                        className="d-flex align-items-center gap-1 hover-text-primary"
                      >
                        <iconify-icon
                          icon="solar:home-smile-angle-outline"
                          className="icon text-lg"
                        ></iconify-icon>
                        Home
                      </a>
                    </li>
                    <li>-</li>
                    <li className="fw-medium">User Client Access 2</li>
                  </ul>
                </div>
                <a
                  href="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#addClientpop"
                  aria-controls="addClientpop"
                  className="btn btn-primary text-sm btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2"
                >
                  <iconify-icon
                    icon="ic:baseline-plus"
                    className="icon text-xl line-height-1"
                  ></iconify-icon>{" "}
                  New Client
                </a>
              </div>
                <ClientStatusCounter 
                  groupCounter={groupCounter}
                  projectStatus={projectStatus}
                />
              <div className="d-flex gap-2 align-items-center py-16 mt-16">
                {/* Search start */}
                <div className="search">
                  <span className="searchBtn position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
                      ></path>
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M338.29 338.29L448 448"
                      ></path>
                    </svg>
                  </span>
                  <SearchInput onChange={handleSearch}/>
                </div>
                {/* Search End */}
                {/* Date Range piker start  */}
                <div className="datepker position-relative">
                  <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <iconify-icon
                      icon="solar:calendar-linear"
                      className="icon text-lg"
                    />
                  </span>
                  <input
                    className="form-control radius-8 bg-base"
                    type="date"
                    id="flat"
                    placeholder="Date"
                  />
                </div>
                {/* Date Range piker end  */}
                {/* Filter btn start */}
                {/* <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#filterAddClientpop"
                    aria-controls="filterAddClientpop"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 radius-8 px-20 py-9 text-sm d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
                      />
                    </svg>{" "}
                    Filter
                  </button>
                </div> */}
                {/* Filter btn end */}
                {/* Manage Column btn start */}
                {/* <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#manageColRightPopup"
                    aria-controls="manageColRightPopup"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 text-sm radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      fill="currentColor"
                      className="bi bi-columns"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm8.5 0v8H15V2zm0 9v3H15v-3zm-1-9H1v3h6.5zM1 14h6.5V6H1z" />
                    </svg>{" "}
                    Manage Column
                  </button>
                </div> */}
                {/* Manage Column btn end */}
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-neutral-900">
                  Clients Details
                </h6>
              </div>
              <ClientList
                filteredData={filteredData}
                handleNewProject={handleNewProject}
                handleEditClick={handleEditClick}
                handleEditStatusClick={handleEditStatusClick}
                isLoading={isLoading}
                handleCancelClick={handleCancelClick}
                edit={edit}
                handleInputChange={handleInputChange}
                handleInputStatusChange={handleInputStatusChange}
                handleSaveClick={handleSaveClick}

                handleInputChange1={handleInputChange1}
                handleSaveClick1={handleSaveClick1}
                handleCancelClick1={handleCancelClick1}
                handleEditClick1={handleEditClick1}
                edit3={edit3}
                profitAndLoss={profitAndLoss}
              />
            </div>
          </div>
        </div>
      </div>

      {/* === Start Add Client Sec ===  */}
      <div
        className="offcanvas offcanvas-end filterSecPopup"
        id="addClientpop"
        aria-labelledby="addClientSec"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="addClientSec">
            Add Client
          </h5>
          <button
            type="button"
            className="btn-close d-none"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body pt-0">
          <Formik
            initialValues={ClientDefaultProps}
            validationSchema={ClientYupSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(formikProps) => (
              <Render
                {...formikProps}
              />
            )}
          </Formik>
        </div>
      </div>
      <FilterPopup />
      <AssignTask
        users={users}
        getUserList={getUserList}
        getClientList={getClientList}
      />
      <ManageColum />
    </>

  )
}

export default ClientContainer