export const REQUEST_STATUS = {
    SUCCESS: 'SUCCESS',
    PENDING: 'PENDING',
    ERROR: 'ERROR',
};

export const  TIME_PERIOD=[
        {key:"All",value:"All Time"},
        {key:"Today",value:"Today"},
        {key:"Yesterday",value:"Yesterday"},
        {key:"Days_7",value:"Last 7 Days"},
        {key:"Days_15",value:"Last 15 Days"},
        {key:"Days_30",value:"Last 30 Days"},
        {key:"Month_6",value:"Last 6 Month"},
        {key:"Year_1",value:"Last 1 Year"},
        
]

export const SEARCH_RECORD_PAGE_LIMIT = 100;

export const DATE_TIME_FORMAT = {
    TIMEAGO: 'TIMEAGO',
    DATE: 'DD/MM/YYYY',
};

export const DEFAULT_DP = '/assets/images/logo.svg';

export default {
    REQUEST_STATUS,
    SEARCH_RECORD_PAGE_LIMIT,
    DATE_TIME_FORMAT,
    DEFAULT_DP,
};
