import React, { useState, useEffect } from 'react';
import FilterPopup from './FilterPopup';
import ManageColumn from './ManageColumn';
import UpsertPopup from './UpsertPopup';
import TaskModuleTab from '../TaskModuleTab';
import { moduleSearchAPI, moduleUpdateAPI } from 'src/api/ModuleApi';
import EditModule from './EditModule';
import { useParams } from 'react-router-dom';
import BackProjectButton from 'src/components/BackProjectButton';
import DatePickerComponent from 'src/components/DatePickerComponent';



const ModuleContainer = () => {

  const [module, setModule] = useState([])
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const { projectId } = useParams();
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedTaskName, setSelectedTaskName] = useState('');
  const [userId, setUserId] = useState(null);
  const [taskNames, setTaskNames] = useState([]);
  const [taskNamesToRemove, setTaskNamesToRemove] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [modeuleId, setModuleId] = useState(null)

  const searchModule = async () => {
    let body = {
      pageLength: 100,
      where: {
        date: {
          $gte: startDate || null,
          $lte: endDate || null
        },
        projectId: projectId
      }
    }
    try {
      const resp = await moduleSearchAPI(body);
      // console.log('Getting response from Module API', resp?.data?.data?.data);
      const filteredData = resp.data.data.data.filter(user => {
        const userDate = new Date(user.ts);
        const isWithinDateRange = (!startDate || userDate >= new Date(startDate)) && (!endDate || userDate <= new Date(endDate));
        const matchesSearch = `${user.moduleName}`.toLowerCase().includes(search.toLowerCase());

        return isWithinDateRange && matchesSearch;
      });
      setModule(filteredData);

    } catch (error) {
      console.log('Error fetching task data', error);
    }
  };

  useEffect(() => {
    searchModule();
  }, [search, startDate, endDate]);

  const handleEditButtonClick = (task) => {
    console.log('task data on click of button', task);
    // setDropdownOpen(!dropdownOpen);
    setTimeout(() => {
      setSelectedTaskId(task);
    }, 0);
  };

  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setStartDate(selectedDates[0]);
      setEndDate(selectedDates[1]);
    } else {
      setStartDate('');
      setEndDate('');
    }
  }


  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUserId(storedUser?._id);
    }
  }, []);

  const handleTaskCountClick = (taskIds, moduleId) => {
    const names = taskIds.map(task => task.taskName);
    setTaskNames(names);
    setTaskNamesToRemove(taskIds.map(task => task._id));
    setModuleId(moduleId); // Capture the moduleId
    setPopupVisible(true);
  };

  const removeTaskName = async (taskIdToRemove) => {

    const updatedTaskNamesToRemove = taskNamesToRemove.filter(id => id !== taskIdToRemove && id !== undefined);
    const updatedTaskNames = taskNames.filter((_, index) => taskNamesToRemove[index] !== taskIdToRemove);
    setTaskNames(updatedTaskNames);
    setTaskNamesToRemove(updatedTaskNamesToRemove);
    const validTaskIds = updatedTaskNamesToRemove.filter(id => id !== undefined);
    const data = {
      taskIds: validTaskIds,
      by: userId,
      projectId: projectId
    };
    console.log('body of remove data', data);
    try {
      const resp = await moduleUpdateAPI({ moduleId: modeuleId, data });
      console.log('response from the update API', resp?.data?.data?.data);
      searchModule();
    } catch (error) {
      console.log('error from updateTask', error);
    }

  };


  const closePopup = () => {
    setPopupVisible(false);
    setTaskNames([]);
    setTaskNamesToRemove([]);
  };

  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
              <BackProjectButton/> 
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="#"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Modual Client Details</li>
              </ul>
              <TaskModuleTab />
              <div className="d-flex gap-2 align-items-center py-16">
                {/* Search start */}
                <div className="search">
                  <span className="searchBtn position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
                      ></path>
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeMiterlimit={10}
                        strokeWidth={32}
                        d="M338.29 338.29L448 448"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="form-control radius-8"
                    id="search"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}
                  />
                </div>
                {/* Search End */}
                {/* Date Range piker start  */}
                <DatePickerComponent onDateRangeChange={handleDateRangeChange} />
                {/* <div className="datepker position-relative">
                  <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                    <iconify-icon
                      icon="solar:calendar-linear"
                      className="icon text-lg"
                    />
                  </span>
                  <input
                    className="form-control radius-8 bg-base"
                    type="date"
                    id="flat"
                    placeholder="Date"
                  />
                </div> */}
                {/* Date Range piker end  */}
                {/* Manage Column btn start */}
                {/* <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#manageColRightPopup"
                    aria-controls="manageColRightPopup"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 text-sm radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      fill="currentColor"
                      className="bi bi-columns"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm8.5 0v8H15V2zm0 9v3H15v-3zm-1-9H1v3h6.5zM1 14h6.5V6H1z" />
                    </svg>{" "}
                    Manage Column
                  </button>
                </div> */}
                {/* Manage Column btn end */}
                {/* Filter btn start */}
                {/* <div className="FilterBtn">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#filterRightPopup"
                    aria-controls="filterRightPopup"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
                      />
                    </svg>{" "}
                    Filter
                  </button>
                </div> */}
                {/* Filter btn end */}
              </div>
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-neutral-900">
                  Module Details
                </h6>
                <div className="d-flex align-items-center gap-2">
                  {/* <a
                    href=""
                    className="btn btn-sm bgpnk"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#addTaskModual"
                    aria-controls="addTaskModual"
                  >
                    Add Task
                  </a> */}
                  <a
                    href=""
                    className="btn btn-sm btn-primary-600"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#addModule"
                    aria-controls="addModule"
                  >
                    <i className="ri-add-line" /> Add Module
                  </a>
                </div>
              </div>
              <div className="table-responsive table-container" id="table-container">
                <table className="table basic-border-table mb-0 table-hover">
                  <thead>
                    <tr className="dnd-moved">
                      <th className="w30px dragablefalse" draggable="false">
                        <input className="form-check-input" type="checkbox" />{" "}
                      </th>
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th className="">Module </th>
                      <th className="text-center">Task Count </th>
                      {/* <th className="text-center">Item </th> */}
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody id="table-body">
                    {module?.map((task, index) => (
                      <tr className="dnd-moved">
                        <td draggable="false">
                          <input className="form-check-input" type="checkbox" />
                        </td>
                        <td draggable="false">
                          <label className="form-check-label">{index + 1}</label>
                        </td>
                        <td>
                          <div className="text-primary-600 fw-bold">{task.moduleName}</div>
                        </td>
                        <td
                          className="text-center"
                          onClick={() => handleTaskCountClick(task.taskIds, task?._id)}
                        >
                          {task?.taskIds?.length}
                        </td>
                        <td className="text-center">
                          <button
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#EditModule"
                            aria-controls="EditModule"
                            className="btn mx-auto rounded-pill btn-primary-900 radius-8 px-20 py-2 d-flex align-items-center gap-2"
                            onClick={() => handleEditButtonClick(task)}
                          >
                            Edit{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-pencil"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                            </svg>{" "}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popupVisible && (
        <>
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            zIndex: 999,
            backdropFilter: 'blur(1px)'
          }}></div>

          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
            zIndex: 1000,
            width: '300px',
            maxHeight: '400px',
            overflowY: 'auto'
          }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <h4 style={{ margin: 0, fontSize: '18px', fontWeight: 'bold' }}>Task</h4>
              <button onClick={closePopup} style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '18px' }}>&times;</button>
            </div>
            <ul style={{ listStyle: 'none', paddingLeft: 0, marginBottom: '10px' }}>
              {taskNames.map((name, index) => (
                <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                  <span>{name}</span>
                  <button
                    onClick={() => removeTaskName(taskNamesToRemove[index])}
                    style={{ background: 'none', border: 'none', color: 'red', cursor: 'pointer', fontSize: '18px' }}
                  >
                    &times;
                  </button>
                </li>
              ))}
            </ul>
            {/* <a href="#" style={{ color: '#0073ea', textDecoration: 'none', fontWeight: 'bold' }}>Add Task</a> */}
          </div>
        </>
      )}

      <UpsertPopup onTaskSaved={searchModule} projectId={projectId} />
      <FilterPopup />
      <ManageColumn />
      {selectedTaskId && <EditModule moduleId={selectedTaskId} onTaskSaved={searchModule} projectId={projectId} />}
    </>
  )
}

export default ModuleContainer